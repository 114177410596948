























































































// Vue
import Vue, { PropType } from "vue";
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// Models
import { IQuarry } from "@/core/_models/quarry.model";
// Services
import AdminService from "../../Admin.service";

export default Vue.extend({
  name: "UpdateQuarryDialog",
  mixins: [validationMixin],
  components: {},
  props: {
    quarry: Object as PropType<IQuarry>
  },

  data() {
    return {
      dialog: false,
      isLoading: false,

      // Used for creating new Quarries
      nextIndex: undefined as number | undefined,

      databaseId: undefined as string | undefined,
      index: undefined as number | undefined,
      name: undefined as string | undefined,
      address: undefined as string | undefined | null
    };
  },

  validations: {
    name: { required }
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    initialize(): void {
      this.getNextIndex();

      if (this.quarry) {
        this.databaseId = this.quarry.DatabaseId;
        this.index = this.quarry.Index;
        this.name = this.quarry.Name;
        this.address = this.quarry.Address ?? null;
      }
    },

    getNextIndex(): void {
      AdminService.getAllQuarries(this.currentUser.clientId).then((data: IQuarry[]) => {
        if (!data.length) {
          this.nextIndex = 0;
          return;
        }
        this.nextIndex = data.length;
      });
    },

    submit(): void {
      if (!this.name) return;

      this.isLoading = true;

      const payload: IQuarry = {
        Index: this.index ?? this.nextIndex!,
        Name: this.name,
        Address: this.address ?? null
      };

      if (this.databaseId) payload.DatabaseId = this.databaseId;

      // Create new Quarry
      if (!this.quarry) {
        AdminService.createQuarry(this.currentUser.clientId, payload)
          .then(() => {
            this.$emit("refresh-quarries");
            this.close();
            this.ADD_SUCCESS_MESSAGE("Quarry successfully created.");
          })
          .catch((error) => {
            console.log(error);
            this.ADD_ERROR_MESSAGE("Error creating quarry. Please try again.");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }

      // Update existing Quarry
      else {
        AdminService.updateQuarry(this.currentUser.clientId, payload)
          .then(() => {
            this.$emit("refresh-quarries");
            this.close();
            this.ADD_SUCCESS_MESSAGE("Quarry successfully updated.");
          })
          .catch((error) => {
            console.log(error);
            this.ADD_ERROR_MESSAGE("Error updating quarry. Please try again.");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    close(): void {
      this.name = undefined as string | undefined;
      this.address = undefined as string | undefined;

      this.$v.$reset;
      this.dialog = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    }
  }
});
