














































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "AppSnackbar",

  data() {
    return {
      minHeight: 50
    };
  },

  computed: {
    ...mapGetters({
      errorMessage: "appMessages/getErrorMessage",
      successMessage: "appMessages/getSuccessMessage",
      infoMessage: "appMessages/getInfoMessage"
    })
  },

  methods: {
    ...mapActions("appMessages", {
      CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
      CLEAR_SUCCESS_MESSAGE: "CLEAR_SUCCESS_MESSAGE",
      CLEAR_INFO_MESSAGE: "CLEAR_INFO_MESSAGE"
    })
  }
});
