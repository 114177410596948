
















































// Vue
import Vue from "vue";
// Vuex
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SideBar",

  data() {
    return {
      // Mini toggle
      mini: false,
      drawer: false,

      // Items in the SideBar.
      sidebarItems: [
        {
          section: null,
          icon: null,
          items: [
            {
              title: "Calendar",
              icon: "mdi-calendar-month",
              page: { name: "Calendar" },
              hideFromImpersonation: false
            }
          ],
          roles: ["Dispatcher", "Administrator", "SystemUser"],
          requiresClient: true
        },
        {
          section: null,
          icon: null,
          items: [
            {
              title: "Job Search",
              icon: "mdi-magnify",
              page: { name: "Job Search" },
              hideFromImpersonation: false
            }
          ],
          roles: ["Dispatcher", "Administrator", "SystemUser"],
          requiresClient: true
        },
        {
          section: "Dispatch",
          icon: "mdi-alpha-d-box",
          items: [
            {
              title: "Job Scheduler",
              icon: "mdi-account-hard-hat",
              page: { name: "Dispatch - Job Scheduler" },
              hideFromImpersonation: false
            },
            {
              title: "Customers",
              icon: "mdi-account-group",
              page: { name: "Dispatch - Customers" },
              hideFromImpersonation: true
            }
          ],
          roles: ["Dispatcher", "Administrator", "SystemUser"],
          requiresClient: true
        },
        {
          section: "Administration",
          icon: "mdi-alpha-a-box",
          items: [
            {
              title: "Applications",
              icon: "mdi-dump-truck",
              page: { name: "Administration - Applications" },
              hideFromImpersonation: false
            },
            {
              title: "Materials",
              icon: "mdi-hammer-wrench",
              page: { name: "Administration - Materials" },
              hideFromImpersonation: false
            },
            {
              title: "Quantities",
              icon: "mdi-counter",
              page: { name: "Administration - Quantities" },
              hideFromImpersonation: false
            },
            {
              title: "Quarries",
              icon: "mdi-image-filter-hdr",
              page: { name: "Administration - Quarries" },
              hideFromImpersonation: false
            },
            {
              title: "Users",
              icon: "mdi-account",
              page: { name: "Administration - Users" },
              hideFromImpersonation: false
            },
            {
              title: "Settings",
              icon: "mdi-cog",
              page: { name: "Administration - Settings" },
              hideFromImpersonation: false
            }
          ],
          roles: ["Administrator", "SystemUser"],
          requiresClient: true
        },
        {
          section: "System",
          icon: "mdi-alpha-s-box",
          items: [
            {
              title: "Clients",
              icon: "mdi-account-tie",
              page: { name: "System - Clients" },
              hideFromImpersonation: false
            }
          ],
          roles: ["SystemUser"],
          requiresClient: false
        }
      ]
    };
  },

  methods: {},

  computed: {
    ...mapGetters(["currentUser"]),
    filteredItems(): any {
      let filteredSidebarItems = this.sidebarItems.filter(
        (sb) => sb.roles.indexOf(this.currentUser.userType) > -1
      );

      // ? If the user is the Impersonation user, hide the Customers section.
      if (this.currentUser.userId === process.env.VUE_APP_I_ID) {
        filteredSidebarItems.forEach((item) => {
          item.items = item.items.filter((subItem) => !subItem.hideFromImpersonation);
        });
      }

      if (this.currentUser.client === null || this.currentUser.client === undefined) {
        return filteredSidebarItems.filter((item) => !item.requiresClient);
      } else {
        return filteredSidebarItems;
      }
    }
  }
});
