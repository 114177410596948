import { IApplication } from "@/core/_models/application.model";

export const DEFAULT_APPLICATIONS: IApplication[] = [
  {
    Index: 0,
    Name: "Footing"
  },
  {
    Index: 1,
    Name: "Driveway"
  },
  {
    Index: 2,
    Name: "Front Porch"
  },
  {
    Index: 3,
    Name: "Back Porch"
  },
  {
    Index: 4,
    Name: "Walkways"
  },
  {
    Index: 5,
    Name: "Wheeler"
  },
  {
    Index: 6,
    Name: "Sewer"
  },
  {
    Index: 7,
    Name: "Septic Bed"
  },
  {
    Index: 8,
    Name: "Pool Wheeler"
  },
  {
    Index: 9,
    Name: "Pool"
  },
  {
    Index: 10,
    Name: "Pool Back In"
  },
  {
    Index: 11,
    Name: "Fill Hole"
  },
  {
    Index: 12,
    Name: "Filtration System"
  },
  {
    Index: 13,
    Name: "Trench"
  },
  {
    Index: 14,
    Name: "Tile Base"
  },
  {
    Index: 15,
    Name: "Stockpile"
  },
  {
    Index: 16,
    Name: "Interior Backfill"
  },
  {
    Index: 17,
    Name: "Backfill Lifts"
  },
  {
    Index: 18,
    Name: "Window Well"
  },
  {
    Index: 19,
    Name: "Gardens"
  },
  {
    Index: 20,
    Name: "Access Ramp"
  },
  {
    Index: 21,
    Name: "Playground"
  },
  {
    Index: 22,
    Name: "Gas Station"
  },
  {
    Index: 23,
    Name: "Sidewalks"
  },
  {
    Index: 24,
    Name: "Road Shoulder"
  },
  {
    Index: 25,
    Name: "Extra 2"
  },
  {
    Index: 26,
    Name: "Instructions on Site"
  },
  {
    Index: 27,
    Name: "Inside"
  },
  {
    Index: 28,
    Name: "Tile"
  },
  {
    Index: 29,
    Name: "Inside Extra"
  },
  {
    Index: 30,
    Name: "Garage"
  },
  {
    Index: 31,
    Name: "Sample Application"
  },
  {
    Index: 32,
    Name: "Sample Application"
  },
  {
    Index: 33,
    Name: "Sample Application"
  },
  {
    Index: 34,
    Name: "Sample Application"
  },
  {
    Index: 35,
    Name: "Sample Application"
  },
  {
    Index: 36,
    Name: "Sample Application"
  },
  {
    Index: 37,
    Name: "Sample Application"
  },
  {
    Index: 38,
    Name: "Sample Application"
  },
  {
    Index: 39,
    Name: "Sample Application"
  },
  {
    Index: 40,
    Name: "Sample Application"
  },
  {
    Index: 41,
    Name: "Sample Application"
  }
];
