// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { IUser } from "@/core/_models/user.model";
import { IApplication } from "@/core/_models/application.model";
// Constants
import { DEFAULT_APPLICATIONS } from "@/core/_constants/new-client-defaults/applications.default";
import { DEFAULT_MATERIALS } from "@/core/_constants/new-client-defaults/materials.default";
import { DEFAULT_QUANTITIES } from "@/core/_constants/new-client-defaults/quantities.default";
// Models
import { IJobSite } from "@/core/_models/jobsite.model";
import { IContact } from "@/core/_models/contact.model";
import { ICustomer } from "@/core/_models/customer.model";
import { IClient } from "@/core/_models/client.model";
import { IMaterial } from "@/core/_models/material.model";
import { IQuantity } from "@/core/_models/quantity.model";
import { IQuarry } from "@/core/_models/quarry.model";

const AdminService = {
  async setupDefaults(clientId: string): Promise<any> {
    DEFAULT_APPLICATIONS.forEach((element: IApplication) => {
      return firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Applications`)
        .doc()
        .set(element);
    });

    DEFAULT_MATERIALS.forEach((element: IMaterial) => {
      return firebase.default.firestore().collection(`Clients/${clientId}/Materials`).doc().set(element);
    });

    DEFAULT_QUANTITIES.forEach((element: IQuantity) => {
      return firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Quantities`)
        .doc()
        .set(element);
    });
  },

  /**
   * Gets all Applications.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IApplication[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllApplications(clientId: string): Promise<IApplication[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Applications`)
      .orderBy("Index")
      .get()
      .then((querySnapshot) => {
        const data: IApplication[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IApplication;
          element.DatabaseId = doc.id;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Creates a new Application.
   *
   * @param {string} clientId The Client's ID
   * @param {IApplication} payload The new Application
   * @return {*}  {Promise<any>} Promise-wrapped response.
   */
  async createApplication(clientId: string, payload: IApplication): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Applications`)
      .add(payload)
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Updates an Application
   *
   * @param {string} clientId The client id
   * @param {IApplication} payload The new application payload
   * @returns {Promise<any>} Promie-wrapped reponse
   * @author Nick Brahmimir
   */
  async updateApplication(clientId: string, payload: IApplication): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Applications`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Deletes an Application
   *
   * @param {string} clientId The client id
   * @param {string} id The application id to delete
   * @returns {Promise<any>} Promise-wrapped response
   * @author Nick Brahmimir
   */
  async deleteApplication(clientId: string, id: string): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Applications`)
      .doc(id)
      .delete()
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Gets all Materials.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IMaterial[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllMaterials(clientId: string): Promise<IMaterial[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Materials`)
      .orderBy("Index")
      .get()
      .then((querySnapshot) => {
        const data: IMaterial[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IMaterial;
          element.DatabaseId = doc.id;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Creates a new Material
   *
   * @param {string} clientId The Client's ID
   * @param {IMaterial} payload The new Material
   * @return {*}  {Promise<any>} Promise-wrapped response.
   */
  async createMaterial(clientId: string, payload: IMaterial): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Materials`)
      .add(payload)
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Updates a Material
   *
   * @param {string} clientId The client id
   * @param {IApplication} payload The updated material
   * @returns {Promise<any>} Promise-wrapped response
   */
  async updateMaterial(clientId: string, payload: IApplication): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Materials`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Deletes a Material
   *
   * @param {string} clientId The client id
   * @param {string} id The material id to delete
   * @returns {Promise<any>} Promise-wrapped response
   */
  async deleteMaterial(clientId: string, id: string): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Materials`)
      .doc(id)
      .delete()
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Gets all Quantities.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IQuantity[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllQuantities(clientId: string): Promise<IQuantity[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quantities`)
      .orderBy("Index")
      .get()
      .then((querySnapshot) => {
        const data: IQuantity[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IQuantity;
          element.DatabaseId = doc.id;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Creates a new Quantity.
   *
   * @param {string} clientId The Client's ID.
   * @param {IQuantity} payload The new Quantity.
   * @return {*}  {Promise<any>} Promise-wrapped response.
   */
  async createQuantity(clientId: string, payload: IQuantity): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quantities`)
      .add(payload)
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Updates a Quantity
   *
   * @param {string} clientId The client id
   * @param {IApplication} payload The new quantity payload
   * @returns {Promise<any>} Promise-wrapped response
   * @author Nick Brahimir
   */
  async updateQuantity(clientId: string, payload: IApplication): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quantities`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Deletes a Quantity
   *
   * @param {string} clientId The client id
   * @param {string} id The id of the quantity to delete
   * @returns {Promise<any>} Promise-wrapped response
   */
  async deleteQuantity(clientId: string, id: string): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quantities`)
      .doc(id)
      .delete()
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Gets all Users.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IUser[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllUsers(clientId: string): Promise<IUser[] | any> {
    const data: IUser[] = [];
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Users`)
      .orderBy("UserType")
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = doc.data() as IUser;
          if (user.UserType !== "SystemUser") data.push(user);
        });

        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async createUser(newUser: IUser): Promise<IUser[] | any> {
    return firebase.default
      .auth()
      .createUserWithEmailAndPassword(newUser.Email!, newUser.Password!)
      .then(async (userCredential) => {
        const createdUser = userCredential.user;
        newUser.UserId = createdUser?.uid;
        delete newUser.Password;

        await firebase.default
          .firestore()
          .collection("Clients/" + newUser.ClientId + "/Users")
          .doc(newUser.UserId)
          .set(newUser);
        await firebase.default.firestore().collection("UserClients").doc(newUser.UserId).set({
          ClientId: newUser.ClientId
        });
      })
      .catch((error: any) => {
        console.error(error);
      });
  },

  async updateUser(user: IUser): Promise<IUser[] | any> {
    return firebase.default
      .firestore()
      .collection("Clients/" + user.ClientId + "/Users")
      .doc(user.UserId!)
      .set(user)
      .catch((error: any) => {
        console.error(error);
      });
  },

  async deleteUser(user: IUser): Promise<IUser[] | any> {
    return firebase.default
      .firestore()
      .collection("Clients/" + user.ClientId + "/Users")
      .doc(user.UserId!)
      .delete()
      .then(async () => {
        await firebase.default.firestore().collection("UserClients").doc(user.UserId!).delete();
      })
      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Gets all Customers, ordered by their LastUsedTimestamp.
   *
   * @param {string} clientId Client's ID
   * @param {boolean} [sortTimeStamp] Boolean to sort by TimeStamp
   * @returns {Promise<ICustomer[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllCustomers(clientId: string, sortTimeStamp?: boolean): Promise<ICustomer[] | any> {
    // Default sort - alphabetically
    if (!sortTimeStamp) {
      return firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Customers`)
        .orderBy("Name")
        .get()
        .then((querySnapshot) => {
          const data: ICustomer[] = [];

          querySnapshot.forEach((doc) => {
            const element = doc.data() as ICustomer;
            element.DatabaseId = doc.id;
            data.push(element);
          });

          return data;
        })

        .catch((error) => {
          console.error(error);
        });
    }

    // Sorting by LastUsedTimestamp
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers`)
      .orderBy("LastUsedTimestamp", "desc")
      .get()
      .then((querySnapshot) => {
        const data: ICustomer[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as ICustomer;
          element.DatabaseId = doc.id;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Creates a Customer.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async createCustomer(clientId: string, payload: ICustomer): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers`)
      .add(payload)
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Updates a Customer.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async updateCustomer(clientId: string, payload: ICustomer): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Deletes a Customer.
   *
   * @param {string} clientId Client's ID
   * @param {string} id The Customer's ID
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async deleteCustomer(clientId: string, id: string): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers`)
      .doc(id)
      .delete()
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Gets all Contacts for a Customer.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IContact[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllCustomerContacts(clientId: string, databaseId: string): Promise<IContact[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/Contacts`)
      .orderBy("Name")
      .get()
      .then((querySnapshot) => {
        const data: IContact[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IContact;
          element.DatabaseId = doc.id;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Creates a Customer Contact.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async createCustomerContact(clientId: string, databaseId: string, payload: IContact): Promise<any> {
    // Generate the new Document
    const doc = firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/Contacts`)
      .doc();

    // Assign the payload ID to the generated Document's ID
    payload.DatabaseId = doc.id;

    // Update the generated Document from above with the payload metadata
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/Contacts`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Updates a Customer Contact.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async updateCustomerContact(clientId: string, databaseId: string, payload: IContact): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/Contacts`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Deletes a Customer Contact.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async deleteCustomerContact(clientId: string, databaseId: string, id: string): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/Contacts`)
      .doc(id)
      .delete()
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Creates a Customer JobSite.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async createCustomerJobSite(clientId: string, databaseId: string, payload: IJobSite): Promise<any> {
    // Generate the new Document
    const doc = firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/JobSites`)
      .doc();

    // Assign the payload ID to the generated Document's ID
    payload.DatabaseId = doc.id;

    // Update the generated Document from above with the payload metadata
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/JobSites`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Updates a Customer JobSite.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async updateCustomerJobSite(clientId: string, databaseId: string, payload: IJobSite): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/JobSites`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Deletes a Customer JobSite.
   *
   * @param {string} clientId Client's ID
   * @param {ICustomer} payload The Customer
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async deleteCustomerJobSite(clientId: string, databaseId: string, id: string): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/JobSites`)
      .doc(id)
      .delete()
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Gets all Jobsites for a Customer.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IJobSite[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllCustomerJobsites(
    clientId: string,
    databaseId: string,
    sortTimeStamp?: boolean
  ): Promise<IJobSite[] | any> {
    // Default return - sorted alphabetically
    if (!sortTimeStamp) {
      return firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Customers/${databaseId}/JobSites`)
        .orderBy("Name")
        .get()
        .then((querySnapshot) => {
          const data: IJobSite[] = [];

          querySnapshot.forEach((doc) => {
            const element = doc.data() as IJobSite;
            element.DatabaseId = doc.id;

            // ! If a JobSite doesn't have a Name property, assign the StreetAddress as a temp name for display purposes.
            if (!element.Name) element.Name = element.StreetAddress ?? "Jobsite unnamed";

            data.push(element);
          });

          return data;
        })

        .catch((error) => {
          console.error(error);
        });
    }

    // Sorting by LastUsedTimestamp
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Customers/${databaseId}/JobSites`)
      .orderBy("LastUsedTimestamp", "desc")
      .get()
      .then((querySnapshot) => {
        const data: IJobSite[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IJobSite;
          element.DatabaseId = doc.id;

          // ! If a JobSite doesn't have a Name property, assign the StreetAddress as a temp name for display purposes.
          if (!element.Name) element.Name = element.StreetAddress ?? "Jobsite unnamed";

          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  async createClient(): Promise<void> {
    // TODO
  },

  /**
   * Updates a Client.
   *
   * @param {string} clientId Client's ID
   * @param {IClient} data Client object
   * @returns {Promise<any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async updateClient(clientId: string, data: IClient): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients`)
      .doc(clientId)
      .set(data)
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  async deleteClient(): Promise<void> {
    // TODO
  },

  /**
   * Gets all Users for a Client.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IUser[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllClientUsers(clientId: string): Promise<IUser[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Users`)
      .get()
      .then((querySnapshot) => {
        const data: IUser[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IUser;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Gets all Quarries.
   *
   * @param {string} clientId Client's ID
   * @returns {Promise<IQuarry[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllQuarries(clientId: string): Promise<IQuarry[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quarries`)
      .orderBy("Index")
      .get()
      .then((querySnapshot) => {
        const data: IQuarry[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IQuarry;
          element.DatabaseId = doc.id;
          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Creates a new Quarry
   *
   * @param {string} clientId The Client ID
   * @param {IQuarry} payload The new Quarry
   * @returns {Promise<any>} Promise-wrapped response
   * @author Nick Brahimir
   */
  async createQuarry(clientId: string, payload: IQuarry): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quarries`)
      .add(payload)
      .then(() => {
        const success = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Updates a Quarry
   *
   * @param {string} clientId The Client ID
   * @param {IQuarry} payload The new Quarry data
   * @returns {Promise<any>} Promise-wrapped response.
   * @author Nick Brahimir
   */
  async updateQuarry(clientId: string, payload: IQuarry): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quarries`)
      .doc(payload.DatabaseId)
      .set(payload)
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  },

  /**
   * Deletes a Quarry
   *
   * @param {string} clientId The Client ID
   * @param {string} quarryId The Quarry ID
   * @returns {Promise<any>} Promise-wrapped response.
   * @author Nick Brahimir
   */
  async deleteQuarry(clientId: string, quarryId: string): Promise<any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Quarries`)
      .doc(quarryId)
      .delete()
      .then(() => {
        const success: any = {
          status: 204,
          message: "OK"
        };

        return success;
      })

      .catch((error: any) => {
        console.error(error);
      });
  }
};

export default AdminService;
