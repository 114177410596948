// Vue
import Vue from "vue";
import Router from "vue-router";
// Store
import store from "@/core/services/store";
// Models
import { AuthUser } from "./core/_models/authUser.model";

Vue.use(Router);

const routes = [
  // Calendar and Job Search
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("@/views/calendar/Calendar.vue")
  },
  {
    path: "/job-search",
    name: "Job Search",
    component: () => import("@/views/job-search/JobSearch.vue")
  },

  // Auth Pages
  {
    path: "/auth/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue")
  },
  {
    path: "/auth/forgot-password",
    name: "Forgot Password",
    component: () => import("@/views/auth/ForgotPassword.vue")
  },

  // Dispatch Pages
  {
    path: "/dispatch/job-scheduler",
    name: "Dispatch - Job Scheduler",
    component: () => import("@/views/dispatch/jobs/JobScheduler.vue")
  },
  {
    path: "/dispatch/customers",
    name: "Dispatch - Customers",
    component: () => import("@/views/dispatch/customers/Customers.vue")
  },

  // Admin Pages
  {
    path: "/admin/applications",
    name: "Administration - Applications",
    component: () => import("@/views/admin/Applications.vue")
  },
  {
    path: "/admin/materials",
    name: "Administration - Materials",
    component: () => import("@/views/admin/Materials.vue")
  },
  {
    path: "/admin/quantities",
    name: "Administration - Quantities",
    component: () => import("@/views/admin/Quantities.vue")
  },
  {
    path: "/admin/quarries",
    name: "Administration - Quarries",
    component: () => import("@/views/admin/Quarries.vue")
  },
  {
    path: "/admin/users",
    name: "Administration - Users",
    component: () => import("@/views/admin/Users.vue")
  },
  {
    path: "/admin/settings",
    name: "Administration - Settings",
    component: () => import("@/views/admin/Settings.vue")
  },

  // System Pages
  {
    path: "/system/clients",
    name: "System - Clients",
    component: () => import("@/views/system/SystemClients.vue")
  }
];

const routeNames: string[] = [];
routes.forEach((element) => {
  routeNames.push(element.name!);
});

const router = new Router({
  routes
});

// Check if User is authenticated before each page route.
router.beforeEach((to, from, next) => {
  // Scroll page to top on every route change
  window.scrollTo(0, 0);

  // Redirect to Login if user tries to navigate to a Route that doesn't exist.
  if (!routeNames.includes(to.name!)) {
    next({ name: "Login" });
    return;
  }

  // User is NOT Authenticated; redirect to login.
  if (!store.getters.isAuthenticated && to.name !== "Login" && to.name !== "Forgot Password") {
    next({ name: "Login" });
    return;
  }

  // User IS authenticated.
  if (store.getters.isAuthenticated) {
    // If an authenticated User attempts to navigate to login; redirect to Calendar.
    if (to.name === "Login") {
      next({ name: "Calendar" });
      return;
    }

    // ! If user does not have Administrator role, do NOT let them route to Administrator routes.
    if (canAccessAdminPages(store.getters.currentUser, to.name!)) {
      next({ name: "Calendar" });
      return;
    }

    // ! If user does not have SystemUser role, do NOT let them route to system/clients.
    if (canAccessSystemPages(store.getters.currentUser, to.name!)) {
      next({ name: "Calendar" });
      return;
    }

    next();
    return;
  }

  next();
});

function canAccessAdminPages(currentUser: AuthUser, toPageName: string): boolean {
  if (
    currentUser.userType !== "Administrator" &&
    currentUser.userType !== "SystemUser" &&
    toPageName.includes("Administration")
  )
    return true;
  return false;
}

function canAccessSystemPages(currentUser: AuthUser, toPageName: string): boolean {
  if (currentUser.userType !== "SystemUser" && toPageName.includes("System")) return true;
  return false;
}

export default router;
