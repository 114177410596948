


























































// Vue
import Vue, { PropType } from "vue";
// Models
import { IJob } from "@/core/_models/job.model";
import { IJobImage } from "@/core/_models/jobImage.model";

export default Vue.extend({
  name: "ImagesDialog",
  props: {
    isViewing: Boolean,
    job: Object as PropType<IJob>
  },

  data() {
    return {
      dialog: false,

      images: [] as IJobImage[]
    };
  },

  methods: {
    initialize(): void {
      if (!this.job.Images) return;
      this.images = this.job.Images;
    },

    close(): void {
      this.images = [] as IJobImage[];
      this.dialog = false;
    }
  }
});
