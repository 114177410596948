interface AppMessagesState {
  errorMessage: string | undefined;
  successMessage: string | undefined;
  infoMessage: string | undefined;
}

export const state: AppMessagesState = {
  errorMessage: undefined,
  successMessage: undefined,
  infoMessage: undefined
};

const namespaced = true;

const getters = {
  getErrorMessage(state: AppMessagesState): string | undefined {
    return state.errorMessage;
  },

  getSuccessMessage(state: AppMessagesState): string | undefined {
    return state.successMessage;
  },

  getInfoMessage(state: AppMessagesState): string | undefined {
    return state.infoMessage;
  }
};

const mutations = {
  addErrorMessage(state: AppMessagesState, message: string): void {
    state.errorMessage = message;
    setTimeout(() => (state.errorMessage = undefined), 5000);
  },

  clearErrorMessage(state: AppMessagesState): void {
    state.errorMessage = undefined;
  },

  addSuccessMessage(state: AppMessagesState, message: string): void {
    state.successMessage = message;
    setTimeout(() => (state.successMessage = undefined), 5000);
  },

  clearSuccessMessage(state: AppMessagesState): void {
    state.successMessage = undefined;
  },

  addInfoMessage(state: AppMessagesState, message: string): void {
    state.infoMessage = message;
    setTimeout(() => (state.infoMessage = undefined), 5000);
  },

  clearInfoMessage(state: AppMessagesState): void {
    state.infoMessage = undefined;
  }
};

const actions = {
  ADD_ERROR_MESSAGE(context: any, message: string): void {
    context.commit("addErrorMessage", message);
  },

  CLEAR_ERROR_MESSAGE(context: any): void {
    context.commit("clearErrorMessage");
  },

  ADD_SUCCESS_MESSAGE(context: any, message: string): void {
    context.commit("addSuccessMessage", message);
  },

  CLEAR_SUCCESS_MESSAGE(context: any): void {
    context.commit("clearSuccessMessage");
  },

  ADD_INFO_MESSAGE(context: any, message: string): void {
    context.commit("addInfoMessage", message);
  },

  CLEAR_INFO_MESSAGE(context: any): void {
    context.commit("clearInfoMessage");
  }
};

export const appMessages = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
