/**
 * Model for an Error response.
 *
 * @class Error
 * @author Nick Brahimir
 */
export class _Error {
  code: string;
  message: string;

  constructor(code: string, message: string) {
    this.code = code;
    this.message = message;
  }
}
