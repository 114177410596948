export enum DeliveryDateType {
  NotConfirmed = "Not Confirmed",
  WillCall = "Will Call",
  At = "At",
  Before = "Before",
  After = "After",
  Anytime = "Anytime",
  ASAP = "ASAP"
}

export const deliveryDateTypes = [
  DeliveryDateType.NotConfirmed,
  DeliveryDateType.WillCall,
  DeliveryDateType.At,
  DeliveryDateType.Before,
  DeliveryDateType.After,
  DeliveryDateType.Anytime,
  DeliveryDateType.ASAP
];
export function deliveryDateType_getDisplayText(lookup: string): string {
  if (!lookup) return "No DeliveryDateType";
  return DeliveryDateType[lookup];
}
