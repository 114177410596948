






























































































// Vue
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
// Models
import { IJob } from "@/core/_models/job.model";
import { IDriver } from "@/core/_models/driver.model";
import { IQuarryAssignment } from "@/core/_models/quarryAssignment.model";
import { IQuarry } from "@/core/_models/quarry.model";
// Services
import AdminService from "@/views/admin/Admin.service";
// Components
import UpdateQuarryDialog from "@/views/admin/_dialogs/quarries/UpdateQuarryDialog.vue";
import JobsService from "@/views/dispatch/jobs/Jobs.service";

export default Vue.extend({
  name: "AssignQuarries",
  components: { UpdateQuarryDialog },
  props: {
    disabled: Boolean,
    job: Object as PropType<IJob>,
    selectedDrivers: Array as PropType<IDriver[]>
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  },

  data() {
    return {
      dialog: false,
      isLoading: false,

      quarriesData: undefined as IQuarry[] | undefined,
      selectedQuarry: undefined as IQuarry | undefined,
      assignedQuarries: undefined as IQuarryAssignment[] | undefined | null
    };
  },

  methods: {
    initialize(): void {
      this.getAllQuarries();

      if (!this.job) return;
      if (!this.job.AssignedQuarries) this.assignedQuarries = [];
      this.assignedQuarries = this.job.AssignedQuarries;
    },

    getAllQuarries(): void {
      this.isLoading = true;

      AdminService.getAllQuarries(this.currentUser.clientId)
        .then((data: IQuarry[]) => {
          if (!data) return;
          this.quarriesData = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    /**
     * Manual update for AssignedQuarries.
     * @author Nick Brahimir
     */
    updateAssignedQuarries(driver: IDriver, selectedQuarry: IQuarry): void {
      if (!driver || !selectedQuarry) return;

      const newAssignment: IQuarryAssignment = {
        Driver: driver,
        Quarry: selectedQuarry
      };

      // If this Job has no assignedQuarries - add this new assignment.
      if (!this.assignedQuarries) {
        this.assignedQuarries = [newAssignment];
      }

      // Check if the driver coming in already has an existing assignment - if so, replace with the
      // new assignment.
      const index = this.assignedQuarries
        .map((assignment: IQuarryAssignment) => {
          return assignment.Driver.UserId;
        })
        .indexOf(driver.UserId);

      if (index !== -1) {
        this.assignedQuarries.splice(index, 1);
      }

      // Add new assignment.
      this.assignedQuarries.push(newAssignment);
    },

    submit(): void {
      // Build payload
      const payload: IJob = {
        DatabaseId: this.job.DatabaseId,
        Material: this.job.Material ?? null,
        Quantity: this.job.Quantity ?? null,
        Applications: this.job.Applications ?? null,
        JobType: this.job.JobType ?? null,
        Hourly: this.job.Hourly ?? null,
        Customer: this.job.Customer ?? null,
        CustomerContact: this.job.CustomerContact ?? null,
        JobSite: this.job.JobSite ?? null,
        JobSiteDetails: {
          Lot: this.job.JobSiteDetails?.Lot,
          Unit: this.job.JobSiteDetails?.Unit,
          Block: this.job.JobSiteDetails?.Block,
          Municipal: this.job.JobSiteDetails?.Municipal,
          PO: this.job.JobSiteDetails?.PO
        },
        DeliveryDetails: {
          DeliveryDate: this.job.DeliveryDetails.DeliveryDate ?? null,
          DeliveryDateType: this.job.DeliveryDetails.DeliveryDateType ?? null,
          EstimateEndDate: this.job.DeliveryDetails.EstimateEndDate ?? null,
          Price: this.job.DeliveryDetails.Price ?? null,
          PriceType: this.job.DeliveryDetails.PriceType ?? null,
          Notes: this.job.DeliveryDetails.Notes,
          IsTaxable: this.job.DeliveryDetails.IsTaxable
        },
        Images: this.job.Images,
        Completed: this.job.Completed ?? null,
        Created: this.job.Created ?? null,
        CreatedBy: this.job.CreatedBy,
        QuantityType: this.job.QuantityType ?? null,
        SortIndex: this.job.SortIndex ?? null,
        DriverNotes: this.job.DriverNotes ?? null,
        AssignedQuarries: this.assignedQuarries ?? null,
        IsPhoneHiddenToDriver: this.job.IsPhoneHiddenToDriver ?? null,
        Canceled: this.job.Canceled
      };

      // Null the JobSiteDetails if there's no data entered for the fields.
      if (
        !this.job.JobSiteDetails?.Lot &&
        !this.job.JobSiteDetails?.Unit &&
        !this.job.JobSiteDetails?.Block &&
        !this.job.JobSiteDetails?.Municipal &&
        !this.job.JobSiteDetails?.PO
      ) {
        payload.JobSiteDetails = null;
      }

      this.isLoading = true;
      JobsService.updateJob(this.currentUser.clientId, payload)
        .then((response: any) => {
          if (!response) return;
          this.$emit("refresh-jobs");
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    close(): void {
      this.assignedQuarries = undefined;
      this.quarriesData = undefined;

      this.dialog = false;
    }
  }
});
