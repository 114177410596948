






























































// Vue
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IMaterial } from "@/core/_models/material.model";
// Services
import AdminService from "@/views/admin/Admin.service";

export default Vue.extend({
  name: "CreateMaterialDialog",
  mixins: [validationMixin],

  data() {
    return {
      dialog: false,
      nextIndex: undefined as number | undefined,

      name: undefined as string | undefined
    };
  },

  validations: {
    name: { required }
  },

  methods: {
    ...mapActions("appMessages", {
      ADD_ERROR_MESSAGE: "ADD_ERROR_MESSAGE",
      ADD_SUCCESS_MESSAGE: "ADD_SUCCESS_MESSAGE"
    }),

    init(): void {
      this.getNextIndex();
    },

    submit(): void {
      if (!this.name) return;

      const payload: IMaterial = {
        Index: this.nextIndex!,
        Name: this.name
      };

      // * Submit to server
      AdminService.createMaterial(this.currentUser.clientId, payload)
        .then(() => {
          this.$emit("refresh-materials");
          this.close();
          this.ADD_SUCCESS_MESSAGE("Material successfully created.");
        })
        .catch((error) => {
          console.log(error);
          this.ADD_ERROR_MESSAGE("Error creating material. Please try again.");
        });
    },

    getNextIndex(): void {
      AdminService.getAllMaterials(this.currentUser.clientId).then((data: IMaterial[]) => {
        if (!data.length) {
          this.nextIndex = 0;
          return;
        }

        this.nextIndex = data.length;
      });
    },

    close(): void {
      this.name = undefined;

      this.$v.$reset();
      this.dialog = false;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    }
  }
});
