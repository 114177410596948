import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

function invertHex(hex: string) {
  hex = hex.replace("#", "");
  return (Number(`0x1${hex}`) ^ 0xffffff).toString(16).substr(1).toUpperCase();
}

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f60039" // #B71C1C
      }
    }
  }
});
