// Store
import store from "@/core/services/store";
// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Models
import { AuthUser } from "@/core/_models/authUser.model";
import { _Error } from "@/core/_models/_error.model";

const AuthService = {
  /**
   * Logs in a User using Firebase Authentication.
   *
   * @param {string} email The User's email.
   * @param {string} password The User's password.
   * @returns {(Promise<User | any>)} Promise wrapped User or _Error object.
   *
   * @author Nick Brahimir
   */
  async login(email: string, password: string): Promise<AuthUser | any> {
    return await firebase.default
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (data: any) => {
        const userData = data.user;

        const user: AuthUser = {
          firstName: "",
          lastName: "",
          userType: "",
          email: userData.email,
          userId: userData.uid,
          client: undefined,
          clientId: undefined
        };

        let clientId = "";
        let userType = "";

        await firebase.default
          .firestore()
          .collection("UserClients")
          .doc(userData.uid)
          .get()
          .then(async (querySnapshot) => {
            const fbUserClient = querySnapshot.data();

            // * Found a fbUserClient (from login email)
            if (fbUserClient != null) {
              clientId = fbUserClient?.ClientId;
              userType = fbUserClient?.UserType;

              if (userType != null || userType != undefined) {
                user.userType = userType;
              }

              if (clientId != null || clientId != undefined) {
                await firebase.default
                  .firestore()
                  .collection("Clients")
                  .doc(clientId)
                  .get()
                  .then(async (querySnapshot) => {
                    const fbClient = querySnapshot.data();

                    user.client = {
                      DocumentName: fbUserClient?.ClientId,
                      CompanyLogo: fbClient?.CompanyLogo,
                      MainThemeColor: fbClient?.MainThemeColor,
                      MainThemeTextColor: fbClient?.MainThemeTextColor,
                      Name: fbClient?.Name,
                      JobSchedulerTimeIntervals: fbClient?.JobSchedulerTimeIntervals,
                      WorkingDays: fbClient?.WorkingDays
                    };

                    user.clientId = fbUserClient?.ClientId;

                    await firebase.default
                      .firestore()
                      .collection("Clients/" + fbUserClient?.ClientId + "/Users")
                      .doc(userData.uid)
                      .get()
                      .then(async (querySnapshot) => {
                        const fbClientUser = querySnapshot.data();

                        if (fbClientUser != null) {
                          user.firstName = fbClientUser?.FirstName;
                          user.lastName = fbClientUser?.LastName;
                          user.userType = fbClientUser?.UserType;
                          user.clientId = fbClientUser?.ClientId;

                          if (fbClientUser?.UserType == "Driver") {
                            throw new _Error(
                              "403",
                              "This user cannot sign in to the MobilDispatch web portal. Please contact your administrator for more information."
                            );
                          }

                          return user;
                        }
                      })
                      .catch((error) => {
                        throw error;
                      });
                  });
              }
            }

            // * Can NOT find a fbUserClient
            else {
              throw new _Error(
                "401",
                "We could'nt find a user with that email. Please provide a valid email."
              );
            }
          })
          .catch((error) => {
            throw error;
          });

        return user;
      })

      // ! Error signing in
      .catch((e: _Error) => {
        // ? Overwrites Firebase's default error message for invalid password logins.
        if (e.code.includes("auth")) {
          e.message = this.processFirebaseErrorMessages(e);
        }

        const error = new _Error(e.code, e.message);
        throw error;
      });
  },

  /**
   * Logs out a User using Firebase Authentication.
   *
   * @returns {(Promise<any>)} Promise wrapped _Error or general response object.
   *
   * @author Nick Brahimir
   */
  logout(): Promise<any> {
    return (
      firebase.default
        .auth()
        .signOut()
        .then(() => {
          const response = {
            code: "ok",
            message: "Successfully signed out!"
          };

          return response;
        })

        // ! Error signing out
        .catch((e: _Error) => {
          const error: _Error = new _Error(e.code, e.message);
          return error;
        })
    );
  },

  /**
   * Sends a password reset link to the email provided using Firebase Authentication.
   *
   * @param {string} email The email to send the password reset link to.
   * @returns {Promise<any | _Error>} Promise wrapped _Error or general response.
   *
   * @author Nick Brahimir
   */
  resetPassword(email: string): Promise<any | _Error> {
    return firebase.default
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        // Successfully sent password reset email.
        const response = {
          code: "ok",
          message: "Successfully signed out!"
        };

        return response;
      })
      .catch((e: any) => {
        // ! Error sending password reset email.
        const error = new _Error(e.code, e.message);
        return error;
      });
  },

  /**
   * Gets the current User from Vuex store.
   *
   * @returns {(User | null)} The User or null.
   *
   * @author Nick Brahimir
   */
  getCurrentUser(): AuthUser | null {
    return store.getters.currentUser;
  },

  /**
   * Gets the Main Theme Color for the currentUser in Vuex Store.
   * Returns a red (#F60039) color theme by default.
   *
   * @returns {(string)} The User or null.
   *
   * @author Nick Brahimir
   */
  getMainThemeColor(): string {
    const color = store.getters.currentUser.client.MainThemeColor;
    return color ?? "#f60039";
  },

  /**
   * Provides more meaningful error messages from Firebase error codes.
   *
   * @param {_Error} error The Firebase error.
   * @return {*}  {string} The meaningful error message.
   * @author Nick Brahimir
   */
  processFirebaseErrorMessages(error: _Error): string {
    switch (error.code) {
      case "auth/user-not-found":
        return "No user found with that email address. Please contact your administrator to create an account.";

      case "auth/wrong-password":
        return "Invalid email or password. Please try again.";

      default:
        return "A generic Firebase error has occurred. Please contact your administrator, or try again.";
    }
  }
};

export default AuthService;
