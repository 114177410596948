export enum QuantityType {
  Loads = "Loads",
  Tons = "Tons",
  Yards = "Yards"
}

export const quantityTypes = [QuantityType.Loads, QuantityType.Tons, QuantityType.Yards];

export function quantityType_getDisplayText(lookup: string): string {
  if (!lookup) return "No QuantityType";
  return QuantityType[lookup];
}
