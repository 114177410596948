import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "..";
import { Job } from "@/core/_models/job.model";
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

class JobsState {
  items: Job[] | undefined = undefined;
}

export const state: JobsState = {
  items: undefined
};

const namespaced: boolean | undefined = true;

const getters = {
  items(state) {
    if (!state.items) return [];

    return state.items.sort((a, b) => (a.SortIndex < b.SortIndex ? -1 : 1));
  },
  item: (state) => (DatabaseId: string): Job | undefined => {
    if (!state.items) {
      return undefined;
    }

    const job = state.items.find((x) => x.DatabaseId == DatabaseId);

    return job;
  }
} as GetterTree<JobsState, any>;

const mutations = {
  SET_JOBS(state, jobs: Job[]) {
    state.items = jobs;
  },
  UPDATE_JOB(state, job: Job) {
    if (!state.items) return;

    const existingItem = state.items.findIndex((x) => x.DatabaseId === job.DatabaseId);

    if (existingItem !== -1) {
      Vue.set(state.items, existingItem, job);
    } else {
      state.items.push(job);
    }
  },
  REMOVE_JOB(state, DatabaseId: string) {
    if (state.items != undefined) {
      state.items = state.items?.filter((x) => x.DatabaseId != DatabaseId);
    }
  }
} as MutationTree<JobsState>;

const actions = {
  async fetchActiveJobs({ commit, rootState }) {
    const data = Array<Job>();
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Jobs")
      .where("Completed", "!=", true)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const job = doc.data() as Job;
          job.DatabaseId = doc.id;
          data.push(job);
        });
        commit("SET_JOBS", data);
      });
  },
  async fetchJobs({ commit, rootState }) {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 1);

    const data = Array<Job>();
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Jobs")
      .where("Created", ">=", d)
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const job = doc.data() as Job;
          job.DatabaseId = doc.id;
          data.push(job);
        });
        commit("SET_JOBS", data);
      });
  },
  pushJobs({ commit, rootState }): any {
    const jobs = rootState.jobs.items;

    jobs.forEach((job: Job) => {
      commit("UPDATE_JOB", job);
    });
  },
  async setJob({ commit, rootState }, job: Job): Promise<any> {
    if (job.DatabaseId === undefined) {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Jobs")
        .add(job)
        .then(() => {
          commit("UPDATE_JOB", job);
        });
    } else {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Jobs")
        .doc(job.DatabaseId)
        .set(job)
        .then(() => {
          commit("UPDATE_JOB", job);
        });
    }
  },
  async removeJob({ commit, rootState }, job: Job): Promise<any> {
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Jobs")
      .doc(job.DatabaseId)
      .delete()
      .then(async () => {
        commit("REMOVE_JOB", job.DatabaseId);
      });
  }
} as ActionTree<JobsState, any>;

export const jobs: Module<JobsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
