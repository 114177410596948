// Vue
import Vue from "vue";
// Vuex store
import { RootState } from "..";
import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Models
import { Customer } from "@/core/_models/customer.model";

class CustomersState {
  items: Customer[] | undefined = undefined;
}

export const state: CustomersState = {
  items: undefined
};

const namespaced: boolean | undefined = true;

const getters = {
  items(state) {
    if (!state.items) return [];

    return state.items.sort((a, b) => (a.Name < b.Name ? -1 : 1));
  },
  item: (state) => (DatabaseId: string): Customer | undefined => {
    if (!state.items) {
      return undefined;
    }

    const customer = state.items.find((x) => x.DatabaseId == DatabaseId);

    return customer;
  }
} as GetterTree<CustomersState, any>;

const mutations = {
  SET_CUSTOMERS(state, customers: Customer[]) {
    state.items = customers;
  },
  UPDATE_CUSTOMER(state, customer: Customer) {
    if (!state.items) return;

    const existingItem = state.items.findIndex((x) => x.DatabaseId === customer.DatabaseId);

    if (existingItem !== -1) {
      Vue.set(state.items, existingItem, customer);
    } else {
      state.items.push(customer);
    }
  },
  REMOVE_CUSTOMER(state, DatabaseId: string) {
    if (state.items != undefined) {
      state.items = state.items?.filter((x) => x.DatabaseId != DatabaseId);
    }
  }
} as MutationTree<CustomersState>;

const actions = {
  async fetchCustomers({ commit, rootState }) {
    const data = Array<Customer>();
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Customers")
      .orderBy("Name")
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(async (customerDoc) => {
          const customer = customerDoc.data() as Customer;
          customer.DatabaseId = customerDoc.id;
          customer.JobSites = [];
          customer.Contacts = [];
          data.push(customer);
        });
        commit("SET_CUSTOMERS", data);
      });
  },
  pushCustomers({ commit, rootState }): any {
    const customers = rootState.customers.items;

    customers.forEach((customer: Customer) => {
      commit("UPDATE_CUSTOMER", customer);
    });
  },
  async setCustomer({ commit, rootState }, customer: Customer): Promise<any> {
    if (customer.DatabaseId === undefined) {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Customers")
        .add(customer)
        .then(() => {
          commit("UPDATE_CUSTOMER", customer);
        });
    } else {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Customers")
        .doc(customer.DatabaseId)
        .set(customer)
        .then(() => {
          commit("UPDATE_CUSTOMER", customer);
        });
    }
  },
  async removeCustomer({ commit, rootState }, customer: Customer): Promise<any> {
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Customers")
      .doc(customer.DatabaseId)
      .delete()
      .then(async () => {
        commit("REMOVE_CUSTOMER", customer.DatabaseId);
      });
  }
} as ActionTree<CustomersState, any>;

export const customers: Module<CustomersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
