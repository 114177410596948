






















































































































































































































































































































































// Vue
import Vue, { PropType } from "vue";
// moment.js
import moment from "moment";
// Constants
import {
  DeliveryDateType,
  deliveryDateType_getDisplayText
} from "@/core/_constants/deliveryDateTypes.constant";
import { JobType, jobType_getDisplayText } from "@/core/_constants/jobTypes.constant";
import { quantityType_getDisplayText } from "@/core/_constants/quantityTypes.constant";
// Models
import { IJob } from "@/core/_models/job.model";
import { IDriver } from "@/core/_models/driver.model";
import { IQuarryAssignment } from "@/core/_models/quarryAssignment.model";
// Components
import UpdateJobDialog from "@/views/dispatch/jobs/job-dashboard/_dialogs/UpdateJobDialog.vue";
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";
import ExportJob from "@/core/components/content/ExportJob.vue";
import AssignQuarries from "@/views/admin/_dialogs/quarries/AssignQuarries.vue";

export default Vue.extend({
  name: "JobCard",
  components: {
    UpdateJobDialog,
    ConfirmationDialog,
    ExportJob,
    AssignQuarries
  },
  props: {
    job: Object as PropType<IJob>,
    drivers: Array as PropType<IDriver[]>,
    isViewing: Boolean
  },

  mounted() {
    // console.log("this.job", this.job);
  },

  methods: {
    refreshJobs(): void {
      this.$emit("refresh-jobs");
    },

    updateJob(): void {
      this.$emit("update-job", this.job);
    },

    deleteJob(): void {
      this.$emit("delete-job", this.job);
    },

    cancelJob(): void {
      this.$emit("cancel-job", this.job);
    },

    completeJob(): void {
      this.$emit("complete-job", this.job);
    },

    /**
     * Resturns the Status for a Driver in the job.Drivers array
     * @author Justin Blackwell & Nick Brahimir
     */
    getIconDriverStatus(jobDrivers: IDriver[], driverId: string): string {
      const result = jobDrivers.filter((x) => x.UserId === driverId)[0].Status;
      return result;
    },

    /**
     * Removes a Driver from the respective array of Drivers on a Job.
     * @author Nick Brahimir
     */
    removeDriver(driverArray: IDriver[], driver: IDriver): IDriver[] {
      const index = driverArray.map((e) => e.UserId).indexOf(driver.UserId);

      if (index >= 0) {
        driverArray.splice(index, 1);

        // Remove Driver from AssignedQuarries array as well.
        if (this.job.AssignedQuarries)
          this.removeAssignedQuarryDriver(this.job.AssignedQuarries, driver);

        return driverArray;
      }

      return driverArray;
    },

    removeAssignedQuarryDriver(
      quarryAssignmentArray: IQuarryAssignment[],
      driver: IDriver
    ): IQuarryAssignment[] {
      const index = quarryAssignmentArray.map((e) => e.Driver.UserId).indexOf(driver.UserId);

      if (index >= 0) {
        quarryAssignmentArray.splice(index, 1);
        return quarryAssignmentArray;
      }

      return quarryAssignmentArray;
    },

    hasNotes(job: IJob): boolean {
      if (job.DeliveryDetails) if (job.DeliveryDetails.Notes) return true;
      if (job.DriverNotes) if (job.DriverNotes.length > 0) return true;
      return false;
    },

    hasImages(job: IJob): boolean {
      if (job.Images) if (job.Images.length > 0) return true;
      return false;
    },

    hasPrice(job: IJob): boolean {
      if (job.DeliveryDetails?.Price) return true;
      return false;
    },

    isDumpJob(job: IJob): boolean {
      if (JobType[job.JobType] === JobType.Dump) return true;
      return false;
    },

    isPumpJob(job: IJob): boolean {
      if (JobType[job.JobType] === JobType.Pump) return true;
      return false;
    },

    isToday(date: any): boolean {
      // ? Convert the Unix timestamp
      const incomingDate = moment(date.seconds * 1000).startOf("day");
      const dateToday = moment(new Date()).startOf("day");

      return incomingDate.isSame(dateToday);
    },

    isTomorrow(date: any): boolean {
      // ? Convert the Unix timestamp
      const incomingDate = moment(date.seconds * 1000).startOf("day");
      const dateToday = moment(new Date()).startOf("day");

      return incomingDate.isSame(dateToday.add(1, "days"));
    },

    isYesterday(previousJob: IJob, currentJob: IJob): boolean {
      // ? Convert the Unix timestamp
      const currentDate = moment(currentJob.DeliveryDetails.DeliveryDate.seconds * 1000).startOf("day");
      const previousDate = moment(previousJob.DeliveryDetails.DeliveryDate.seconds * 1000).startOf(
        "day"
      );

      if (currentDate.isSame(previousDate)) {
        return true;
      }

      return false;
    },

    displayDeliveryDateType(deliveryDateType: string): string | undefined {
      return deliveryDateType_getDisplayText(deliveryDateType);
    },

    displayJobType(jobType: string): string | undefined {
      return jobType_getDisplayText(jobType);
    },

    displayQuantityType(quantityType: string): string | undefined {
      return quantityType_getDisplayText(quantityType);
    },

    isRedText(deliveryDateType: string): boolean {
      switch (DeliveryDateType[deliveryDateType]) {
        case DeliveryDateType.NotConfirmed:
          return true;

        case DeliveryDateType.WillCall:
          return true;

        case DeliveryDateType.Anytime:
          return true;

        case DeliveryDateType.ASAP:
          return true;

        default:
          return false;
      }
    }
  },

  filters: {
    moment: function (date: any) {
      try {
        return moment(date.toDate()).format("MMMM Do, YYYY");
      } catch {
        try {
          return moment(date).format("MMMM Do, YYYY");
        } catch {
          return "";
        }
      }
    },

    momentDateTime: function (date: any) {
      try {
        return moment(date.toDate()).format("dddd, MMMM Do YYYY");
      } catch {
        try {
          return moment(date).format("dddd, MMMM Do YYYY");
        } catch {
          return "";
        }
      }
    },

    momentTime: function (date: any) {
      try {
        return moment(date.toDate()).format("h:mm A");
      } catch {
        try {
          return moment(date).format("h:mm A");
        } catch {
          return "";
        }
      }
    }
  }
});
