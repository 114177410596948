



































































// Vue
import Vue from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { IContact } from "@/core/_models/contact.model";

export default Vue.extend({
  name: "CreateContactDialog",
  mixins: [validationMixin],

  data() {
    return {
      dialog: false,
      isValid: false,

      name: "",
      email: "",
      phone: "",
      extension: ""
    };
  },

  validations: {
    name: { required }
  },

  methods: {
    /**
     * Submits the New Job to the server and updates the list.
     * @author Nick Brahimir
     */
    submit(): void {
      const newContact: IContact = {
        Name: this.name,
        Email: this.email,
        Phone: this.phone,
        Ext: this.extension
      };

      this.$emit("created-contact", newContact);
      this.close();
    },

    /**
     * Clears the form.
     * @author Nick Brahimir
     */
    close(): void {
      this.name = "";
      this.phone = "";
      this.extension = "";
      this.email = "";

      this.$v.$reset();
      this.dialog = false;
    }
  },

  computed: {
    /**
     * Parses any Name errors.
     * @author Nick Brahimir
     */
    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    }
  }
});
