// Vue
import Vue from "vue";
// Vuex
import Vuex, { StoreOptions } from "vuex";
// Router
import router from "@/router";
// Models
import { AuthUser } from "@/core/_models/authUser.model";
import { IDriver } from "@/core/_models/driver.model";
import { IJob } from "@/core/_models/job.model";
// Store Modules
import { applications } from "./modules/applications";
import { systemUsers } from "./modules/systemUsers";
import { appMessages } from "./modules/appMessages";
import { quantities } from "./modules/quantities";
import { customers } from "./modules/customers";
import { materials } from "./modules/materials";
import { clients } from "./modules/clients";
import { users } from "./modules/users";
import { jobs } from "./modules/jobs";

Vue.use(Vuex);

export class RootState {
  currentUser: AuthUser | null = null;
  activeJobs: IJob[] = [];
  drivers: IDriver[] = [];
  updateDashboard = false;
  updateCalendar = false;
  updateScheduler = false;
}

const store: StoreOptions<RootState> = {
  state: {
    currentUser: null,
    activeJobs: [] as IJob[],
    drivers: [] as IDriver[],
    updateDashboard: false,
    updateCalendar: false,
    updateScheduler: false
  },

  getters: {
    currentUser(state: RootState) {
      return state.currentUser;
    },

    isAuthenticated(state: RootState) {
      return state.currentUser != null;
    },

    activeJobs(state: RootState): IJob[] {
      return state.activeJobs;
    },

    updateDashboard(state: RootState): boolean {
      return state.updateDashboard;
    },

    updateCalendar(state: RootState): boolean {
      return state.updateCalendar;
    },

    updateScheduler(state: RootState): boolean {
      return state.updateScheduler;
    },

    drivers(state: RootState): IDriver[] {
      return state.drivers;
    }
  },

  mutations: {
    login(state: RootState, currentUser: AuthUser): void {
      state.currentUser = currentUser;
    },

    logout(state: RootState): void {
      state.currentUser = null;
    },

    setActiveJobs(state: RootState, activeJobs: IJob[]): void {
      if (activeJobs === undefined) return;
      state.activeJobs = activeJobs;
    },

    setUpdateDashboard(state: RootState, flag: boolean): void {
      state.updateDashboard = flag;
    },

    setUpdateCalendar(state: RootState, flag: boolean): void {
      state.updateCalendar = flag;
    },

    setUpdateScheduler(state: RootState, flag: boolean): void {
      state.updateScheduler = flag;
    },

    setDrivers(state: RootState, drivers: IDriver[]): void {
      if (drivers === undefined) return;
      state.drivers = drivers;
    },

    setJobSchedulerTimeIntervals(state: RootState, interval: number): void {
      if (!state.currentUser) return;
      if (interval === undefined) return;
      state.currentUser.client!.JobSchedulerTimeIntervals = interval;
    }
  },

  actions: {
    LOGIN(context: any, currentUser: AuthUser) {
      context.commit("login", currentUser);
      router.push({ name: "Calendar" });
    },

    LOGOUT(context: any) {
      context.commit("logout");
      router.push({ name: "Login" });
    },

    SET_ACTIVE_JOBS(context: any, activeJobs: IJob[]) {
      context.commit("setActiveJobs", activeJobs);
    },

    SET_UPDATE_DASHBOARD(context: any, flag: boolean) {
      context.commit("setUpdateDashboard", flag);
    },

    SET_UPDATE_CALENDAR(context: any, flag: boolean) {
      context.commit("setUpdateCalendar", flag);
    },

    SET_UPDATE_SCHEDULER(context: any, flag: boolean) {
      context.commit("setUpdateScheduler", flag);
    },

    SET_DRIVERS(context: any, drivers: IDriver[]) {
      context.commit("setDrivers", drivers);
    },

    SET_JOBSCHEDULER_TIME_INTERVALS(context: any, interval: number) {
      context.commit("setJobSchedulerTimeIntervals", interval);
    },
  },

  modules: {
    jobs: jobs,
    // activeJobs: activeJobs,
    customers: customers,
    applications: applications,
    materials: materials,
    quantities: quantities,
    users: users,
    clients: clients,
    systemUsers: systemUsers,
    appMessages: appMessages
  }
};

export default new Vuex.Store<RootState>(store);
