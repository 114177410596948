import { IMaterial } from "@/core/_models/material.model";

export const DEFAULT_MATERIALS: IMaterial[] = [
  {
    Index: 0,
    Name: '3/4" Clear'
  },
  {
    Index: 1,
    Name: '3/4" Gran A'
  },
  {
    Index: 2,
    Name: '1/2" Clear'
  },
  {
    Index: 3,
    Name: '2" Gran B'
  },
  {
    Index: 4,
    Name: '1/4" Clear'
  },
  {
    Index: 5,
    Name: '1/2" Gran M'
  },
  {
    Index: 6,
    Name: "HPB"
  },
  {
    Index: 7,
    Name: "Screenings"
  },
  {
    Index: 8,
    Name: "HL8"
  },
  {
    Index: 9,
    Name: "Recycled"
  },
  {
    Index: 10,
    Name: "Premium Top Soil"
  },
  {
    Index: 11,
    Name: "Playground Mulch"
  },
  {
    Index: 12,
    Name: "Contractor Soil"
  },
  {
    Index: 13,
    Name: "Mulch"
  },
  {
    Index: 14,
    Name: "Topsoil"
  },
  {
    Index: 15,
    Name: "Playground Sand"
  },
  {
    Index: 16,
    Name: "Triple Mix"
  },
  {
    Index: 17,
    Name: "Brick Sand"
  },
  {
    Index: 18,
    Name: "Lawn Blend"
  },
  {
    Index: 19,
    Name: "Cable Sand"
  },
  {
    Index: 20,
    Name: '2" Clear'
  },
  {
    Index: 21,
    Name: "Septic Sand"
  },
  {
    Index: 22,
    Name: "Round Stone"
  },
  {
    Index: 23,
    Name: "Filter Sand"
  },
  {
    Index: 24,
    Name: "Concrete Sand"
  },
  {
    Index: 25,
    Name: "Man Sand"
  },
  {
    Index: 26,
    Name: "Sample Material"
  },
  {
    Index: 27,
    Name: "Sample Material"
  },
  {
    Index: 28,
    Name: "Sample Material"
  },
  {
    Index: 29,
    Name: "Sample Material"
  },
  {
    Index: 30,
    Name: "Sample Material"
  },
  {
    Index: 31,
    Name: "Sample Material"
  },
  {
    Index: 32,
    Name: "Sample Material"
  },
  {
    Index: 33,
    Name: "Sample Material"
  },
  {
    Index: 34,
    Name: "Sample Material"
  },
  {
    Index: 35,
    Name: "Sample Material"
  }
];
