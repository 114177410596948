/**
 * Debounces a function to ensure it is only called once after a specified wait time has passed.
 * @param {Function} fn - The function to be debounced.
 * @param {number} wait - The wait time in milliseconds.
 * @returns {Function} - The debounced function.
 */
export function debounce<T extends (...args: any[]) => void>(
  fn: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timer: ReturnType<typeof setTimeout>;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }

    timer = window.setTimeout(() => {
      fn.apply(this, args); // call the function if time expires
    }, wait);
  };
}
