// Vue
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
// Vuex
import store from "@/core/services/store";
// Vuetify
import vuetify from "@/plugins/vuetify";
// Material Design Icons
import "@mdi/font/css/materialdesignicons.css";
// Bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// Firebase
import firebase from "firebase/app";
import firebaseConfig from "@/core/_firebase/firebaseConfig";
import "firebase/storage";
// FontAwesome Imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
// FontAwesome Usings
library.add(faDollarSign);
library.add(faExclamation);
library.add(faCamera);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Global Usings
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
