







































































// Vue
import Vue from "vue";
import { mapGetters } from "vuex";
// moment.js
import moment from "moment";
// Models
import { IJob } from "@/core/_models/job.model";
import { IDriver } from "@/core/_models/driver.model";
import { IQuarryAssignment } from "@/core/_models/quarryAssignment.model";
// Services
import JobsService from "@/views/dispatch/jobs/Jobs.service";
// Components
import JobCard from "@/core/components/content/JobCard.vue";
import UpdateJobDialog from "@/views/dispatch/jobs/job-dashboard/_dialogs/UpdateJobDialog.vue";

export default Vue.extend({
  name: "JobDashboard",
  components: { UpdateJobDialog, JobCard },

  data() {
    return {
      isLoading: false,
      refreshJobsTimer: {} as any,

      // Jobs Data
      jobsData: [] as IJob[],

      // Drivers Data
      driversData: [] as IDriver[]
    };
  },

  mounted() {
    this.getAllActiveJobs();
    this.getAllDrivers();
    this.refreshJobsInterval();
  },

  destroyed() {
    clearInterval(this.refreshJobsTimer);
  },

  methods: {
    getAllActiveJobs(): void {
      this.isLoading = true;

      JobsService.getAllActiveJobs(this.currentUser.clientId).then((data: IJob[]) => {
        if (!data) return;
        this.jobsData = data;
        this.isLoading = false;
      });
    },

    getAllDrivers(): void {
      JobsService.getAllDrivers(this.currentUser.clientId).then((data: IDriver[]) => {
        if (!data) return;
        this.driversData = data;
      });
    },

    mapQuarryAssignments(
      drivers: IDriver[] | undefined | null,
      quarryAssignments: IQuarryAssignment[]
    ): IQuarryAssignment[] {
      const finalizedAssignments = quarryAssignments;

      // If Job has no Drivers assigned to it; return
      if (!drivers || !drivers.length) return finalizedAssignments;

      // Add any missing QuarryAssignments
      if (drivers.length !== quarryAssignments.length) {
        drivers.forEach((driver) => {
          const driverAssignmentLookup = quarryAssignments.find(
            (a) => a.Driver.UserId === driver.UserId
          );

          if (!driverAssignmentLookup) {
            const newAssignment: IQuarryAssignment = {
              Driver: driver,
              Quarry: null
            };

            finalizedAssignments.push(newAssignment);
          }
        });
      }

      return finalizedAssignments;
    },

    updateJob(job: IJob): void {
      if (!job) return;
      if (!job.DatabaseId) return;

      this.isLoading = true;

      // Initialize new property: AssignedQuarries for Jobs that don't have it in db.
      let assignedQuarries = job.AssignedQuarries;
      if (!assignedQuarries) assignedQuarries = [];
      assignedQuarries = this.mapQuarryAssignments(job.Drivers, assignedQuarries);

      // Build payload
      const payload: IJob = {
        DatabaseId: job.DatabaseId,
        Material: job.Material,
        Quantity: job.Quantity,
        Applications: job.Applications,
        JobType: job.JobType,
        Hourly: job.Hourly,
        Customer: job.Customer,
        CustomerContact: job.CustomerContact,
        JobSite: job.JobSite,
        JobSiteDetails: job.JobSiteDetails,
        DeliveryDetails: job.DeliveryDetails,

        Drivers: job.Drivers,
        DriverIds: job.DriverIds,

        Completed: job.Completed,
        Images: job.Images,
        Created: job.Created,
        CreatedBy: job.CreatedBy,
        QuantityType: job.QuantityType,
        SortIndex: job.SortIndex,
        DriverNotes: job.DriverNotes,
        AssignedQuarries: assignedQuarries,
        IsPhoneHiddenToDriver: job.IsPhoneHiddenToDriver,
        Canceled: job.Canceled
      };

      // Build payload DriverIds
      const driverIds: string[] = [];

      job.Drivers!.forEach((element) => {
        driverIds.push(element.UserId);
      });

      payload.DriverIds = driverIds;

      JobsService.updateJob(this.currentUser.clientId, payload).then((response: any) => {
        if (!response) return;

        this.getAllActiveJobs();
        this.updateStore();
        this.isLoading = false;
      });
    },

    deleteJob(job: IJob): void {
      if (!job) return;
      if (!job.DatabaseId) return;

      JobsService.deleteJob(this.currentUser.clientId, job.DatabaseId).then((response: any) => {
        if (!response) return;

        this.getAllActiveJobs();
        this.updateStore();
        this.isLoading = false;
      });
    },

    cancelJob(job: IJob): void {
      if (!job) return;
      if (!job.DatabaseId) return;

      JobsService.cancelJob(this.currentUser.clientId, job).then((response: any) => {
        if (!response) return;

        this.getAllActiveJobs();
        this.updateStore();
        this.isLoading = false;
      });
    },

    completeJob(job: IJob): void {
      if (!job) return;
      if (!job.DatabaseId) return;

      this.isLoading = true;

      // Build payload
      const payload: IJob = {
        DatabaseId: job.DatabaseId,
        Material: job.Material,
        Quantity: job.Quantity,
        Applications: job.Applications,
        JobType: job.JobType,
        Hourly: job.Hourly,
        Customer: job.Customer,
        CustomerContact: job.CustomerContact,
        JobSite: job.JobSite,
        JobSiteDetails: job.JobSiteDetails,
        DeliveryDetails: job.DeliveryDetails,
        Completed: job.Completed,
        Images: job.Images,
        Created: job.Created,
        CreatedBy: job.CreatedBy,
        QuantityType: job.QuantityType,
        SortIndex: job.SortIndex,
        DriverNotes: job.DriverNotes,
        AssignedQuarries: job.AssignedQuarries ?? null,
        IsPhoneHiddenToDriver: job.IsPhoneHiddenToDriver,
        Canceled: job.Canceled
      };

      // Set payload Completed
      payload.Completed = true;

      JobsService.updateJob(this.currentUser.clientId, payload).then((response: any) => {
        if (!response) return;

        this.getAllActiveJobs();
        this.updateStore();
        this.isLoading = false;
      });
    },

    updateStore(): void {
      this.$store.dispatch("SET_UPDATE_CALENDAR", true);
      this.$store.dispatch("SET_UPDATE_DASHBOARD", true);
      this.$store.dispatch("SET_UPDATE_SCHEDULER", true);
    },

    /**
     * Fetches Jobs every 30 seconds
     * @author Nick Brahimir
     */
    refreshJobsInterval(): void {
      this.refreshJobsTimer = setInterval(() => {
        this.getAllActiveJobs();
      }, 15000);
    },

    /**
     * Resturns the Status for a Driver in the job.Drivers array
     * @author Justin Blackwell & Nick Brahimir
     */
    getIconDriverStatus(jobDrivers: IDriver[], driverId: string): string {
      const result = jobDrivers.filter((x) => x.UserId === driverId)[0].Status;
      return result;
    },

    /**
     * Removes a Driver from the respective array of Drivers on a Job.
     * @author Nick Brahimir
     */
    removeDriver(driverArray: IDriver[], driver: IDriver): IDriver[] {
      const index = driverArray.map((e) => e.UserId).indexOf(driver.UserId);

      if (index >= 0) {
        driverArray.splice(index, 1);
        return driverArray;
      }

      return driverArray;
    },

    hasNotes(job: IJob): boolean {
      if (job.DeliveryDetails) if (job.DeliveryDetails.Notes) return true;
      if (job.DriverNotes) if (job.DriverNotes.length > 0) return true;
      return false;
    },

    hasImages(job: IJob): boolean {
      if (job.Images) if (job.Images.length > 0) return true;
      return false;
    },

    isDumpJob(job: IJob): boolean {
      if (job.JobType.toLowerCase() === "dump") return true;
      return false;
    },

    isPumpJob(job: IJob): boolean {
      if (job.JobType.toLowerCase() === "pump") return true;
      return false;
    },

    isToday(date: any): boolean {
      // ? Convert the Unix timestamp
      const incomingDate = moment(date.seconds * 1000).startOf("day");
      const dateToday = moment(new Date()).startOf("day");

      return incomingDate.isSame(dateToday);
    },

    isTomorrow(date: any): boolean {
      // ? Convert the Unix timestamp
      const incomingDate = moment(date.seconds * 1000).startOf("day");
      const dateToday = moment(new Date()).startOf("day");

      return incomingDate.isSame(dateToday.add(1, "days"));
    },

    isYesterday(previousJob: IJob, currentJob: IJob): boolean {
      // ? Convert the Unix timestamp
      const currentDate = moment(currentJob.DeliveryDetails.DeliveryDate.seconds * 1000).startOf("day");
      const previousDate = moment(previousJob.DeliveryDetails.DeliveryDate.seconds * 1000).startOf(
        "day"
      );

      if (currentDate.isSame(previousDate)) {
        return true;
      }

      return false;
    },

    isRedText(deliveryDateType: string): boolean {
      if (deliveryDateType.toLowerCase() === "not confirmed") return true;
      if (deliveryDateType.toLowerCase() === "will call") return true;
      if (deliveryDateType.toLowerCase() === "anytime") return true;
      if (deliveryDateType.toLowerCase() === "asap") return true;
      return false;
    },

    close(): void {
      this.$emit("close-dashboard");
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      activeJobs: "activeJobs",
      updateDashboard: "updateDashboard"
    })
  },

  watch: {
    updateDashboard(): void {
      if (this.updateDashboard) {
        this.getAllActiveJobs();
        this.getAllDrivers();
        this.$store.dispatch("SET_UPDATE_DASHBOARD", false);
      }
    }
  },

  filters: {
    moment: function (date: any) {
      try {
        return moment(date.toDate()).format("MMMM Do, YYYY");
      } catch {
        try {
          return moment(date).format("MMMM Do, YYYY");
        } catch {
          return "";
        }
      }
    },

    momentDateTime: function (date: any) {
      try {
        return moment(date.toDate()).format("dddd, MMMM Do YYYY");
      } catch {
        try {
          return moment(date).format("dddd, MMMM Do YYYY");
        } catch {
          return "";
        }
      }
    },

    momentTime: function (date: any) {
      try {
        return moment(date.toDate()).format("h:mm A");
      } catch {
        try {
          return moment(date).format("h:mm A");
        } catch {
          return "";
        }
      }
    }
  }
});
