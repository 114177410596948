





















































import Vue from "vue";

export default Vue.extend({
  name: "ConfirmationDialog",
  props: {
    loading: Boolean,
    disabled: Boolean,
    width: Number,
    height: Number,
    elevation: Number,
    dialogButtonWidth: Number,
    dialogButtonHeight: Number,
    dialogButtonText: String,
    dialogButtonColor: String,
    dialogButtonIcon: String,
    dialogButtonIconSmall: Boolean,
    dialogButtonIsBlock: Boolean,
    dialogButtonIsTile: Boolean,
    dialogButtonIsFab: Boolean,
    dialogButtonIsIcon: Boolean,
    dialogButtonIsText: Boolean,
    dialogButtonIsTextWhite: Boolean,
    dialogButtonIsSmall: Boolean,
    dialogButtonIsXSmall: Boolean,
    headerTitle: String,
    confirmationMessage: String,
    acceptText: String,
    acceptColor: String,
    declineText: String,
    declineColor: String
  },

  data() {
    return {
      dialog: false
    };
  },

  methods: {
    confirmed: function (): void {
      this.$emit("is-confirmed", true);
      this.dialog = false;
    }
  }
});
