






// Vue
import Vue from "vue";
// App Layout
import Layout from "@/core/components/layout/_Layout.vue";

export default Vue.extend({
  name: "App",
  components: { Layout }
});
