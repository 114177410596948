


















// Vue
import Vue from "vue";
// Vuex
import { mapGetters } from "vuex";
// Components
import SideBar from "@/core/components/layout/SideBar.vue";
import HeaderBar from "@/core/components/layout/HeaderBar.vue";
import AppSnackbar from "@/core/components/layout/AppSnackbar.vue";

export default Vue.extend({
  name: "Layout",
  components: { SideBar, HeaderBar, AppSnackbar },

  methods: {
    isFlexCentered(): boolean {
      if (!this.isAuthenticated) return true;
      if (this.$route.name === "Calendar") return true;
      if (this.$route.name === "Dispatch - Job Scheduler") return true;

      return false;
    }
  },

  computed: {
    ...mapGetters(["isAuthenticated"])
  }
});
