export enum JobType {
  Sling = "Sling",
  Dump = "Dump",
  Pump = "Pump"
}

export const jobTypes = [JobType.Sling, JobType.Dump, JobType.Pump];

export function jobType_getDisplayText(lookup: string): string {
  if (!lookup) return "No JobType";
  return JobType[lookup];
}
