
























































































































































































































































































































































































































































































































































































































































































































// Vue
import Vue, { PropType } from "vue";
// Vuex
import { mapActions, mapGetters } from "vuex";
// Vuelidate
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
// moment.js
import moment from "moment";
// Firebase
import "firebase/auth";
import "firebase/firestore";
import * as firebase from "firebase/app";
// Constants
import { priceTypes } from "@/core/_constants/priceTypes.constant";
import { JobType, jobTypes } from "@/core/_constants/jobTypes.constant";
import { quantityTypes } from "@/core/_constants/quantityTypes.constant";
import { DeliveryDateType } from "@/core/_constants/deliveryDateTypes.constant";
import { deliveryDateTypes } from "@/core/_constants/deliveryDateTypes.constant";
// Models
import { IJob } from "@/core/_models/job.model";
import { IQuarry } from "@/core/_models/quarry.model";
import { IDriver } from "@/core/_models/driver.model";
import { IContact } from "@/core/_models/contact.model";
import { IJobSite } from "@/core/_models/jobsite.model";
import { IMaterial } from "@/core/_models/material.model";
import { ICustomer } from "@/core/_models/customer.model";
import { IQuantity } from "@/core/_models/quantity.model";
import { IDriverNote } from "@/core/_models/driverNotes.model";
import { IApplication } from "@/core/_models/application.model";
import { IDeliveryDetails } from "@/core/_models/deliveryDetails.model";
import { IQuarryAssignment } from "@/core/_models/quarryAssignment.model";
// Services
import AdminService from "@/views/admin/Admin.service";
import JobsService from "@/views/dispatch/jobs/Jobs.service";
// Components
import ImagesDialog from "@/views/calendar/ImagesDialog.vue";
import ExportJob from "@/core/components/content/ExportJob.vue";
import UpdateQuarryDialog from "@/views/admin/_dialogs/quarries/UpdateQuarryDialog.vue";
import ConfirmationDialog from "@/core/components/content/_dialogs/ConfirmationDialog.vue";
import CreateMaterialDialog from "@/views/admin/_dialogs/materials/CreateMaterialDialog.vue";
import CreateApplicationDialog from "@/views/admin/_dialogs/applications/CreateApplicationDialog.vue";
import CreateQuantityDialog from "@/views/admin/_dialogs/quantities/CreateQuantityDialog.vue";
import CreateContactDialog from "@/views/dispatch/customers/_dialogs/contact/CreateContactDialog.vue";
import CreateJobsiteDialog from "@/views/dispatch/customers/_dialogs/job-site/CreateJobsiteDialog.vue";
import CreateCustomerDialog from "@/views/dispatch/customers/_dialogs/customer/CreateCustomerDialog.vue";
import { JobSchedulerSelection } from "../../scheduler/_models/jobSchedulerSelection.model";

export default Vue.extend({
  name: "UpdateJobDialog",
  components: {
    ExportJob,
    ImagesDialog,
    UpdateQuarryDialog,
    ConfirmationDialog,
    CreateJobsiteDialog,
    CreateContactDialog,
    CreateCustomerDialog,
    CreateMaterialDialog,
    CreateQuantityDialog,
    CreateApplicationDialog
  },
  props: {
    isViewing: Boolean,
    job: Object as PropType<IJob>,
    drivers: Array as PropType<IDriver[]>,
    inputData: Object as PropType<JobSchedulerSelection>,
    noActivators: Boolean,
    showDialog: { type: Boolean, default: undefined }
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
      timeModal: false,

      assignedDrivers: [] as IDriver[],

      // Dropdown data
      priceTypes: priceTypes,
      contacts: [] as IContact[],
      jobSites: [] as IJobSite[],
      quantityTypes: quantityTypes,
      jobTypes: jobTypes,
      deliveryDateTypes: deliveryDateTypes,

      // Material
      materialsData: [] as IMaterial[],
      selectedMaterial: null as IMaterial | null,

      // Quantity
      quantitiesData: [] as IQuantity[],
      selectedQuantity: null as IQuantity | null,
      selectedQuantityType: "Loads",

      // Applications
      applicationsData: [] as IApplication[],
      selectedApplications: [] as IApplication[],
      selectedJobType: JobType.Sling,
      applicationIsHourly: false,
      applicationIsMulti: false,

      // Quarries
      quarriesData: undefined as IQuarry[] | undefined,
      selectedQuarry: undefined as IQuarry | undefined,
      assignedQuarries: [] as IQuarryAssignment[],

      // Customer
      customersData: [] as ICustomer[],
      selectedCustomer: null as ICustomer | null,
      selectedContact: null as IContact | null,
      IsPhoneHiddenToDriver: false,
      selectedJobSite: null as IJobSite | null,

      // Jobsite Details
      lotNumber: "",
      unitNumber: "",
      block: "",
      municipal: "",
      po: "",
      deliveryDetailNotes: "",

      // Jobsite Notes
      jobsiteNotes: undefined as string | undefined,

      // Delivery Details
      // Datepicker
      deliveryDate: undefined as string | undefined,
      dateMenu: false,
      jobDuration: undefined as number | undefined,

      // Timepicker
      deliveryTime: moment(new Date()).format("HH:mm").toString(),
      timePickerMenu: false,

      // Delivery Date Type
      selectedDeliveryDateType: DeliveryDateType.At,

      // Price Type
      price: "",
      selectedPriceType: "Ton",
      isTaxable: true,
      taxRate: 13,

      // Notes
      deliveryDetails: {} as IDeliveryDetails,
      driverNotes: [] as IDriverNote[],

      // Images
      images: [] as any,

      // Is Job Complete
      isCompleted: false,

      // First Available Job Time
      firstAvailableTime: undefined as Date | undefined
    };
  },

  mixins: [validationMixin],

  validations: {
    // Customer Info
    selectedCustomer: { required },

    // Delivery Details
    deliveryDate: { required },
    deliveryTime: { required }
  },

  methods: {
    ...mapActions("customers", {
      setCustomer: "setCustomer"
    }),

    initializeFormValues(): void {
      // console.log("job", this.job);

      this.getAllMaterials();
      this.getAllQuantities();
      this.getAllApplications();
      this.getAllQuarries();
      this.getAllCustomers();

      this.deliveryDate = moment(new Date()).format("YYYY-MM-DD");
      this.getFirstAvailableTime();

      if (this.inputData) {
        const startDate = moment(this.inputData.startDate);
        this.deliveryDate = startDate.format("YYYY-MM-DD");
        this.deliveryTime = startDate.format("HH:mm");
        this.assignedDrivers.push(this.inputData.driver);
      }

      if (this.job) {
        // Drivers
        this.assignedDrivers = this.job.Drivers ?? [];

        // Material
        this.selectedMaterial = this.job.Material ?? null;

        // Quantity
        this.selectedQuantity = this.job.Quantity ?? null;
        this.selectedQuantityType = this.job.QuantityType;

        // Application
        this.selectedApplications = this.job.Applications ?? null;
        this.selectedJobType = JobType[this.job.JobType];
        this.applicationIsHourly = this.job.Hourly;
        this.applicationIsMulti = this.job.Applications.length > 1;

        // Customer
        this.selectedCustomer = this.job.Customer;
        this.getCustomerInfo(this.selectedCustomer);
        this.selectedContact = this.job.CustomerContact;
        this.selectedJobSite = this.job.JobSite;

        // Jobsite Details
        this.lotNumber = this.job.JobSiteDetails?.Lot!;
        this.unitNumber = this.job.JobSiteDetails?.Unit!;
        this.block = this.job.JobSiteDetails?.Block!;
        this.municipal = this.job.JobSiteDetails?.Municipal!;
        this.po = this.job.JobSiteDetails?.PO!;
        this.deliveryDetailNotes = this.job.DeliveryDetails?.Notes!;

        // Jobsite Notes
        this.jobsiteNotes = this.job.JobSite?.Notes;

        // Delivery Details
        // DatePicker
        this.deliveryDate = moment(this.job.DeliveryDetails?.DeliveryDate.toDate()).format("YYYY-MM-DD");
        this.dateMenu = false;

        // TimePicker
        this.deliveryTime = moment(this.job.DeliveryDetails?.DeliveryDate.toDate())
          .format("HH:mm")
          .toString();
        this.timePickerMenu = false;

        // Delivery Date Type
        this.selectedDeliveryDateType = DeliveryDateType[this.job.DeliveryDetails?.DeliveryDateType!];

        // Price Type
        this.price = this.job.DeliveryDetails?.Price!;
        this.selectedPriceType = this.job.DeliveryDetails?.PriceType!;
        this.isTaxable = this.job.DeliveryDetails.IsTaxable ?? false;

        // Notes
        this.deliveryDetails = this.job.DeliveryDetails!;
        this.driverNotes = this.job.DriverNotes!;

        // Images
        this.images = this.job.Images;

        // Is Job Complete
        this.isCompleted = this.job.Completed;

        // Get Job Duration
        this.jobDuration = this.getJobDuration(
          this.job.DeliveryDetails.DeliveryDate,
          this.job.DeliveryDetails.EstimateEndDate
        );

        // Assigned Quarries
        this.assignedQuarries = this.job.AssignedQuarries ?? [];
      }
    },

    getAllApplications(setSelectedValue?: boolean): void {
      this.isLoading = true;

      AdminService.getAllApplications(this.currentUser.clientId).then((data: IApplication[]) => {
        if (!data) return;
        this.applicationsData = data;

        // Set selectedValue to last element in the array.
        if (setSelectedValue)
          this.selectedApplications.push(this.applicationsData[this.applicationsData.length - 1]);

        this.isLoading = false;
      });
    },

    getAllMaterials(setSelectedValue?: boolean): void {
      this.isLoading = true;

      AdminService.getAllMaterials(this.currentUser.clientId).then((data: IMaterial[]) => {
        if (!data) return;
        this.materialsData = data;

        // Set selectedValue to last element in the array.
        if (setSelectedValue) this.selectedMaterial = this.materialsData[this.materialsData.length - 1];

        this.isLoading = false;
      });
    },

    getAllQuantities(setSelectedValue?: boolean): void {
      this.isLoading = true;

      AdminService.getAllQuantities(this.currentUser.clientId).then((data: IQuantity[]) => {
        if (!data) return;
        this.quantitiesData = data;

        // Set selectedValue to last element in the array.
        if (setSelectedValue)
          this.selectedQuantity = this.quantitiesData[this.quantitiesData.length - 1];

        this.isLoading = false;
      });
    },

    getAllQuarries(): void {
      AdminService.getAllQuarries(this.currentUser.clientId).then((data: IQuarry[]) => {
        if (!data) return;
        this.quarriesData = data;
      });
    },

    getAllCustomers(): void {
      this.isLoading = true;

      AdminService.getAllCustomers(this.currentUser.clientId, true).then((data: ICustomer[]) => {
        if (!data) return;

        // if selectedCustomer is not undefined then it is a new customer
        if (this.selectedCustomer) {
          var customerIndex = data.findIndex((x) => x.Name == this.selectedCustomer?.Name);
          this.selectedCustomer = data[customerIndex];
          this.getCustomerInfo(this.selectedCustomer);
        }

        this.customersData = data;

        this.isLoading = false;
      });
    },

    getCustomerInfo(customer: ICustomer): void {
      if (!customer) return;
      if (!customer.DatabaseId) return;

      this.isLoading = true;

      this.contacts = [] as IContact[];
      this.jobSites = [] as IJobSite[];

      // Get Customer Contacts
      AdminService.getAllCustomerContacts(this.currentUser.clientId, customer.DatabaseId).then(
        (data: IContact[]) => {
          if (!data) return;
          this.contacts = data;
          this.isLoading = false;
        }
      );

      this.isLoading = true;

      // Get Customer Jobsites
      AdminService.getAllCustomerJobsites(this.currentUser.clientId, customer.DatabaseId, true).then(
        (data: IJobSite[]) => {
          if (!data) return;
          this.jobSites = data;

          this.isLoading = false;
        }
      );
    },

    clearSelectedCustomerInfo(): void {
      this.jobsiteNotes = undefined;
      this.selectedJobSite = null;
      this.selectedContact = null;
    },

    /**
     * Creates/Updates the Job.
     * @author Nick Brahimir
     */
    submit(isDuplicateJob?: boolean): void {
      this.isLoading = true;

      // Build deliveryDate
      let payloadDeliveryDate: any;
      payloadDeliveryDate = moment(this.deliveryDate + "T" + this.deliveryTime).toDate();

      // Set the deliveryTime to the firstAvailableTime if the deliveryDateType is "Anytime"
      if (
        this.selectedDeliveryDateType === DeliveryDateType.NotConfirmed ||
        this.selectedDeliveryDateType === DeliveryDateType.WillCall ||
        this.selectedDeliveryDateType === DeliveryDateType.Anytime ||
        this.selectedDeliveryDateType === DeliveryDateType.ASAP
      )
        payloadDeliveryDate = this.firstAvailableTime;

      // Update Customer's LastUsedTimestamp
      if (this.selectedCustomer) {
        const updatedCustomer: ICustomer = this.selectedCustomer;
        updatedCustomer.LastUsedTimestamp = new Date();

        AdminService.updateCustomer(this.currentUser.clientId, updatedCustomer).then();
      }

      // Update JobSite's LastUsedTimestamp
      if (this.selectedJobSite && this.selectedCustomer) {
        if (!this.selectedCustomer.DatabaseId) return;

        const updatedJobsite: IJobSite = this.selectedJobSite;
        updatedJobsite.LastUsedTimestamp = new Date();

        AdminService.updateCustomerJobSite(
          this.currentUser.clientId,
          this.selectedCustomer.DatabaseId,
          updatedJobsite
        );
      }

      // Build payload DriverIds
      const driverIds = this.assignedDrivers?.map((x) => x.UserId);

      // Build payload
      const payload: IJob = {
        Material: this.selectedMaterial ?? null,
        Quantity: this.selectedQuantity ?? null,
        Applications: this.selectedApplications ?? null,
        JobType: this.selectedJobType.replace(/\s/g, "") ?? null,
        Hourly: this.applicationIsHourly ?? false,
        Customer: this.selectedCustomer!,
        CustomerContact: this.selectedContact ?? null,
        JobSite: this.selectedJobSite ?? null,
        JobSiteDetails: {
          Lot: this.lotNumber ?? null,
          Unit: this.unitNumber ?? null,
          Block: this.block ?? null,
          Municipal: this.municipal ?? null,
          PO: this.po ?? null
        },
        DeliveryDetails: {
          DeliveryDate: payloadDeliveryDate ?? null,
          DeliveryDateType: this.selectedDeliveryDateType.replace(/\s/g, "") ?? null,
          Price: this.price ?? null,
          PriceType: this.selectedPriceType ?? null,
          IsTaxable: this.isTaxable ?? false,
          Notes: this.deliveryDetailNotes ?? null
        },
        Images: [],
        Completed: false,
        Created: new Date(),
        CreatedBy: this.currentUser.userId,
        QuantityType: this.selectedQuantityType.replace(/\s/g, ""),
        SortIndex: 0,
        Drivers: this.assignedDrivers,
        DriverIds: driverIds,
        DriverNotes: this.driverNotes ?? [],
        AssignedQuarries: this.assignedQuarries ?? null,
        IsPhoneHiddenToDriver: this.IsPhoneHiddenToDriver ?? null,
        Canceled: this.job?.Canceled ?? false
      };

      // Null the JobSiteDetails if there's no data entered for the fields.
      if (!this.block && !this.lotNumber && !this.municipal && !this.po && !this.unitNumber)
        payload.JobSiteDetails = null;

      // * Create/Duplicate Job
      if (!this.job || (this.job && isDuplicateJob)) {
        // Create Job (New/Duplicate)

        if (this.inputData?.endDate) {
          // Set end date if initial data was set.
          payload.DeliveryDetails.EstimateEndDate = moment(this.inputData.endDate).toDate();
        } else {
          // Otherwise, default EstimateEndDate to 1 hour from DeliveryDate.
          payload.DeliveryDetails.EstimateEndDate = moment(payloadDeliveryDate).add(1, "hours").toDate();
        }

        // If we're duplicating a Job, include the appropriate properties.
        if (this.job && isDuplicateJob) {
          payload.Drivers = this.job.Drivers ?? null;
          payload.DriverIds = this.job.DriverIds ?? null;
          payload.Images = this.job.Images ?? null;
          payload.DeliveryDetails.EstimateEndDate = this.job.DeliveryDetails.EstimateEndDate ?? null;
        }

        JobsService.createJob(this.currentUser.clientId, payload)
          .then(() => {
            this.updateStore();
          })
          .finally(() => {
            this.isLoading = false;
            this.close();
          });

        return;
      }

      // * Update Job
      if (this.job) {
        // Set fields specific to an existing Job
        payload.DatabaseId = this.job.DatabaseId;
        payload.Completed = this.isCompleted ?? false;
        payload.Canceled = this.job.Canceled;

        // Set the EstimateEndDate using jobDuration.
        if (this.jobDuration) {
          payload.DeliveryDetails.EstimateEndDate = moment(payloadDeliveryDate)
            .add(this.jobDuration, "hours")
            .toDate();
        }

        // Assign Images
        if (this.job.Images?.length) payload.Images = this.job.Images;

        // If Job doesn't have an EstimateEndDate; default to 1 hour from DeliveryDate.
        if (!payload.DeliveryDetails.EstimateEndDate) {
          payload.DeliveryDetails.EstimateEndDate = moment(payload.DeliveryDetails.DeliveryDate)
            .add(1, "hours")
            .toDate();
        }

        JobsService.updateJob(this.currentUser.clientId, payload)
          .then(() => {
            this.updateStore();
          })
          .finally(() => {
            this.isLoading = false;
            this.close();
          });

        return;
      }
    },

    /**
     * Deletes a Job
     * @author Nick Brahimir
     */
    deleteJob(): void {
      if (this.job.DatabaseId) {
        JobsService.deleteJob(this.currentUser.clientId, this.job.DatabaseId).then((response: any) => {
          if (!response) return;

          this.$emit("refresh-jobs");
          this.updateStore();
          this.isLoading = false;
          this.close();
        });
      }
    },

    /**
     * Reopens (uncancels) a Job
     * @author Nick Brahimir
     */
    reopenJob(): void {
      if (!this.job) return;

      this.isLoading = true;
      JobsService.reopenJob(this.currentUser.clientId, this.job)
        .then(() => {
          this.$emit("refresh-jobs");
          this.updateStore();
          this.close();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async createCustomer(newCustomer: ICustomer): Promise<void> {
      this.setCustomer(newCustomer).then(() => {
        this.selectedCustomer = newCustomer;
        this.getAllCustomers();
      });
    },

    async createContact(newContact: IContact): Promise<void> {
      const doc = firebase.default
        .firestore()
        .collection(
          "Clients/" +
            this.currentUser.client.DocumentName +
            "/Customers/" +
            this.selectedCustomer!.DatabaseId! +
            "/Contacts"
        )
        .doc();
      newContact.DatabaseId = doc.id;

      await firebase.default
        .firestore()
        .collection(
          "Clients/" +
            this.currentUser.client.DocumentName +
            "/Customers/" +
            this.selectedCustomer!.DatabaseId! +
            "/Contacts"
        )
        .doc(newContact.DatabaseId)
        .set(newContact)
        .then(() => {
          this.contacts.push(newContact);
          this.contacts = this.contacts.sort((a, b) => (a.Name < b.Name ? -1 : 1));
          this.selectedContact = newContact;
        });
    },

    async createJobSite(newJobSite: IJobSite): Promise<void> {
      const doc = firebase.default
        .firestore()
        .collection(
          "Clients/" +
            this.currentUser.client.DocumentName +
            "/Customers/" +
            this.selectedCustomer!.DatabaseId! +
            "/JobSites"
        )
        .doc();
      newJobSite.DatabaseId = doc.id;

      await firebase.default
        .firestore()
        .collection(
          "Clients/" +
            this.currentUser.client.DocumentName +
            "/Customers/" +
            this.selectedCustomer!.DatabaseId! +
            "/JobSites"
        )
        .doc(newJobSite.DatabaseId)
        .set(newJobSite)
        .then(() => {
          this.jobSites.push(newJobSite);
          this.selectedJobSite = newJobSite;
        });
    },

    getJobsiteNotes(): void {
      this.jobsiteNotes = this.selectedJobSite?.Notes;
    },

    updateStore(): void {
      this.$store.dispatch("SET_UPDATE_CALENDAR", true);
      this.$store.dispatch("SET_UPDATE_DASHBOARD", true);
      this.$store.dispatch("SET_UPDATE_SCHEDULER", true);
    },

    close(): void {
      this.assignedDrivers = [] as IDriver[];

      // Material
      this.selectedMaterial = {} as IMaterial;

      // Quantity
      this.selectedQuantity = {} as IQuantity;
      this.selectedQuantityType = "Loads";

      // Applications
      this.selectedApplications = [] as IApplication[];
      this.selectedJobType = JobType.Sling;
      this.applicationIsHourly = false;
      this.applicationIsMulti = false;

      // Quarries
      this.selectedQuarry = undefined as IQuarry | undefined;
      this.assignedQuarries = [] as IQuarryAssignment[];

      // Customer
      this.selectedCustomer = null as ICustomer | null;
      this.selectedContact = null as IContact | null;
      this.selectedJobSite = null as IJobSite | null;

      // Jobsite Details
      this.lotNumber = "";
      this.unitNumber = "";
      this.block = "";
      this.municipal = "";
      this.po = "";
      this.deliveryDetailNotes = "";

      // Delivery Details
      // Datepicker
      this.deliveryDate = moment(new Date()).format("YYYY-MM-DD");
      this.dateMenu = false;
      this.jobDuration = undefined as number | undefined;

      // Timepicker
      this.deliveryTime = moment(new Date()).format("HH:mm").toString();
      this.timePickerMenu = false;

      // Delivery Date Type
      this.selectedDeliveryDateType = DeliveryDateType.At;

      // Price Type
      this.price = "";
      this.selectedPriceType = "Ton";

      // Notes
      this.deliveryDetails = {} as IDeliveryDetails;
      this.driverNotes = [] as IDriverNote[];

      // First Available Job Time
      this.firstAvailableTime = undefined as Date | undefined;

      this.$v.$reset();
      this.dialog = false;
      this.$emit("close");
    },

    convertTimestamp(time: any): string {
      const parsedDate = moment(time.seconds * 1000)
        .format("dddd, MMMM Do YYYY, h:mm A")
        .toString();
      return parsedDate;
    },

    /**
     * Gets the first available Job time for the day (earliest Job in that day, -1 hour)
     * @author Nick Brahimir
     */
    getFirstAvailableTime(): void {
      this.isLoading = true;

      // If we're creating a new Job, set start and end for today's date.
      let date = moment(this.deliveryDate).toDate();
      let start = new Date(date.setHours(0, 0, 0, 0));
      let end = new Date(date.setHours(23, 59, 59));

      JobsService.getJobsDateRange(this.currentUser.clientId, start, end, true, false)
        .then((data: IJob[]) => {
          if (!data) return;

          // If there's no Jobs scheduled for today, set firstAvailableTime to 6AM.
          if (!data.length) {
            this.firstAvailableTime = moment(new Date(date)).hour(6).minute(0).second(0).toDate();
            return;
          }

          // If we're updating a Job and it's the earliest Job of the day; set the firstAvailableTime to
          // the current DeliveryDate of the Job we're updating (we don't want to -1 hour if it's the
          // earliest in that day).
          if (this.job) {
            if (this.job.DatabaseId === data[0].DatabaseId) {
              this.firstAvailableTime = new Date(data[0].DeliveryDetails.DeliveryDate.seconds * 1000);
              return;
            }
          }

          // ! If the firstAvailableTime is the previous day, default it to 12AM of the current day.
          const momentEarliestJob = moment(
            new Date(data[0].DeliveryDetails.DeliveryDate.seconds * 1000)
          );
          if (momentEarliestJob.hour() == 0) {
            this.firstAvailableTime = momentEarliestJob.hour(0).minute(0).second(0).toDate();
            return;
          }

          this.firstAvailableTime = momentEarliestJob.subtract(1, "hour").toDate();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    /**
     * Gets the numeric difference between a start and end Date for a Job.
     * @author Nick Brahimir
     */
    getJobDuration(start: any, end?: any): number | undefined {
      if (!start || !end) return;

      const startTime = moment(start.seconds * 1000).toISOString();
      const endTime = moment(end.seconds * 1000).toISOString();
      const diffHours = moment
        .duration(moment(endTime, "YYYY/MM/DD HH:mm").diff(moment(startTime, "YYYY/MM/DD HH:mm")))
        .asHours();

      return diffHours;
    },

    showDeliveryTime(deliveryTime: string): boolean {
      if (deliveryTime.toLowerCase() === "not confirmed") return false;
      if (deliveryTime.toLowerCase() === "will call") return false;
      if (deliveryTime.toLowerCase() === "anytime") return false;
      return true;
    },

    getTaxedPrice(): number {
      const taxedPrice = parseFloat(this.price) * (1 + this.taxRate / 100);
      return Math.round(taxedPrice * 100) / 100;
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    selectedCustomerErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.selectedCustomer.required && errors.push("Customer is required");
      return errors;
    },

    deliveryDateErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.deliveryDate.required && errors.push("Delivery date is required");
      return errors;
    },

    deliveryTimeErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.deliveryTime.required && errors.push("Delivery time is required");
      return errors;
    }
  },

  watch: {
    /**
     * Checks if a Driver was added/removed from the assignedDrivers list, and updates the
     * assignedQuarries list accordingly.
     */
    assignedDrivers(): void {
      const assignedDriversDriverIds: string[] = this.assignedDrivers.map((driver) => driver.UserId);
      const assignedQuarriesDriverIds: string[] = this.assignedQuarries.map(
        (assignment) => assignment.Driver.UserId
      );

      // Scenario 1: A Driver was added to assignedDrivers
      if (this.assignedDrivers.length > this.assignedQuarries.length) {
        this.assignedDrivers.forEach((driver) => {
          if (!assignedQuarriesDriverIds.includes(driver.UserId)) {
            const newQuarryAssignment = { Driver: driver, Quarry: null } as IQuarryAssignment;
            this.assignedQuarries.push(newQuarryAssignment);
          }
        });
      }

      // Scenario 2: A Driver was removed from assignedDrivers
      else if (this.assignedDrivers.length < this.assignedQuarries.length) {
        this.assignedQuarries.forEach((assignment) => {
          const index = this.assignedQuarries.indexOf(assignment);
          if (!assignedDriversDriverIds.includes(assignment.Driver.UserId)) {
            this.assignedQuarries.splice(index, 1);
          }
        });
      }
    },

    deliveryDate(): void {
      this.getFirstAvailableTime();
    },

    /** Update dialog state when prop changes. */
    showDialog(newValue: boolean): void {
      this.dialog = newValue;
    },

    /** Refresh form data when new input data (scheduler) is received. */
    inputData(newValue: any): void {
      if (newValue) {
        this.initializeFormValues();
      }
    },

    /** Re-initialize form data when job prop is updated. */
    job(newValue: any): void {
      if (this.noActivators && newValue) {
        this.initializeFormValues();
      }
    }
  },

  mounted() {
    if (this.noActivators) {
      this.initializeFormValues();
    }

    if (this.showDialog) {
      this.dialog = true;
    }
  }
});
