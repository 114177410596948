




















// Vue
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
// moment.js
import moment from "moment";
// jsPDF
import { jsPDF } from "jspdf";
// Constants
import { LOGO_BASE_64 } from "@/assets/media/logos/logo-base64";
// Models
import { IJob } from "@/core/_models/job.model";
import { IApplication } from "@/core/_models/application.model";
import { IDriver } from "@/core/_models/driver.model";
import { DeliveryDateType } from "@/core/_constants/deliveryDateTypes.constant";
import { IDriverNote } from "@/core/_models/driverNotes.model";

export default Vue.extend({
  name: "ExportJob",
  components: {},
  props: {
    job: Object as PropType<IJob>
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" })
  },

  methods: {
    /**
     * Exports the Job as a PDF.
     * ? This function uses a tracked yValue that is used to build each "line-item" in the PDF.
     * ? If we're looking to add more sections, simply add or modify a function that explicitly
     * ? handles the feature/functionality, ensuring that you return the new yValue as you build out
     * ? each new section (email nickb@webility.ca for more information).
     * @author Nick Brahimir
     */
    exportJob(): void {
      const doc = new jsPDF();

      // Name of exported PDF file
      const fileName = `Job Report - ${this.job.Customer.Name} - ${this.formatDateShort(
        this.job.DeliveryDetails.DeliveryDate
      )}.pdf`;

      let yValue = 10;

      yValue = this.pdf_BuildHeader(doc, yValue);
      yValue = this.insertNewLine(doc, yValue);

      // * Customer
      yValue = this.pdf_BuildCustomer(doc, yValue);
      yValue = this.insertNewLine(doc, yValue);

      // * Materials, Quantity, Applications
      yValue = this.pdf_BuildMaterialsQuantitiesApplications(doc, yValue);
      yValue = this.insertNewLine(doc, yValue);

      // * Jobsite Details
      yValue = this.pdf_BuildJobsiteDetails(doc, yValue);
      yValue = this.insertNewLine(doc, yValue);

      // * Drivers
      yValue = this.pdf_BuildDrivers(doc, yValue);

      // * Notes (Dispatcher, Driver, Additional)
      yValue = this.insertNewPage(doc);
      if (this.job.DeliveryDetails.Notes) {
        yValue = this.pdf_BuildDispatcherNotes(doc, yValue, this.job.DeliveryDetails.Notes);
        yValue = this.insertNewLine(doc, yValue);
      }

      if (this.job.DriverNotes?.length) {
        yValue = this.pdf_BuildDriverNotes(doc, yValue, this.job.DriverNotes);
        yValue = this.insertNewLine(doc, yValue);
      }

      yValue = this.pdf_BuildAdditionalNotes(doc, yValue);

      // Download action
      doc.save(fileName);
    },

    pdf_BuildHeader(doc: jsPDF, yValue: number): number {
      // MD Title
      doc.setFontSize(18);
      doc.text(`${this.currentUser.client.Name} Job Report`, 10, (yValue += 10));
      doc.setFontSize(12);

      // Logo
      doc.addImage(LOGO_BASE_64, "PNG", 150, 10, 50, 30);

      // Status (isCompleted)
      // doc.setFontSize(16);
      // if (this.job.Completed) {
      //   doc.setTextColor("green");
      //   doc.text("Complete", 10, (yValue += 10));
      // } else {
      //   doc.setTextColor("red");
      //   doc.text("Incomplete", 10, (yValue += 10));
      // }
      // doc.setTextColor("black");
      // doc.setFontSize(12);

      return yValue;
    },

    pdf_BuildCustomer(doc: jsPDF, yValue: number): number {
      doc.setFontSize(16);
      doc.text(
        `${this.job.Customer.Name ? this.job.Customer.Name : "No Customer specified"}`,
        10,
        (yValue += 10)
      );
      doc.setFontSize(12);

      // Street Address
      doc.text(
        `Street address: ${
          this.job.JobSite?.StreetAddress ? this.job.JobSite?.StreetAddress : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // Customer Notes
      doc.text(
        `Customer notes: ${
          this.job.Customer?.Notes ? this.job.Customer?.Notes : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      yValue = this.insertNewLine(doc, yValue);

      // Delivery Date Type
      doc.text(
        `Delivery date type: ${
          this.job.DeliveryDetails.DeliveryDateType
            ? this.job.DeliveryDetails.DeliveryDateType
            : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // Delivery Date
      doc.text(
        `Delivery date: ${
          this.job.DeliveryDetails.DeliveryDate
            ? this.formatDate(this.job.DeliveryDetails.DeliveryDate)
            : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // Price
      doc.text(
        `Price: $${
          this.job.DeliveryDetails.Price ? this.job.DeliveryDetails.Price : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // Price Type
      doc.text(
        `Price type: ${
          this.job.DeliveryDetails.PriceType
            ? this.job.DeliveryDetails.PriceType
            : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      return yValue;
    },

    pdf_BuildMaterialsQuantitiesApplications(doc: jsPDF, yValue: number): number {
      // Material
      if (!this.job.Material) doc.text("Material: ____________________", 10, (yValue += 10));
      if (this.job.Material) {
        doc.text(
          `Material: ${this.job.Material.Name ? this.job.Material.Name : "____________________"}`,
          10,
          (yValue += 10)
        );
      }

      // Quantity
      if (!this.job.Quantity) doc.text("Quantity: ____________________", 10, (yValue += 10));
      if (this.job.Quantity) {
        doc.text(
          `Quantity: ${this.job.Quantity.Name ? this.job.Quantity.Name : "____________________"} ${
            this.job.QuantityType ? this.job.QuantityType : ""
          }`,
          10,
          (yValue += 10)
        );
      }

      // Applications
      doc.text(
        `Application(s): ${
          this.job.Applications.length
            ? this.getStringApplications(this.job.Applications)
            : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // Job Type
      doc.text(
        `Job type: ${this.job.JobType ? this.job.JobType : "____________________"}`,
        10,
        (yValue += 10)
      );

      return yValue;
    },

    pdf_BuildJobsiteDetails(doc: jsPDF, yValue: number): number {
      // Unit
      doc.text(
        `Unit: ${
          this.job.JobSiteDetails?.Unit ? this.job.JobSiteDetails?.Unit : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // Block
      doc.text(
        `Block: ${
          this.job.JobSiteDetails?.Block ? this.job.JobSiteDetails?.Block : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // Municipal
      doc.text(
        `Municipal: ${
          this.job.JobSiteDetails?.Municipal
            ? this.job.JobSiteDetails?.Municipal
            : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      // PO
      doc.text(
        `PO: ${this.job.JobSiteDetails?.PO ? this.job.JobSiteDetails?.PO : "____________________"}`,
        10,
        (yValue += 10)
      );

      return yValue;
    },

    pdf_BuildDrivers(doc: jsPDF, yValue: number): number {
      doc.text(
        `Assigned Drivers: ${
          this.job.Drivers?.length ? this.getStringDrivers(this.job.Drivers) : "____________________"
        }`,
        10,
        (yValue += 10)
      );

      return yValue;
    },

    pdf_BuildAdditionalNotes(doc: jsPDF, yValue: number): number {
      doc.setFontSize(16);
      doc.text(`Additional Notes`, 10, (yValue += 10));
      doc.setFontSize(12);

      for (let index = 0; index < 10; index++) {
        yValue = this.insertLineSegment(doc, yValue);
      }

      return yValue;
    },

    pdf_BuildDispatcherNotes(doc: jsPDF, yValue: number, dispatcherNotes: string): number {
      if (!dispatcherNotes) return yValue;

      doc.setFontSize(16);
      doc.text(`Dispatcher Notes`, 10, (yValue += 10));
      doc.setFontSize(12);
      doc.text(`${dispatcherNotes}`, 10, (yValue += 10));

      return yValue;
    },

    pdf_BuildDriverNotes(doc: jsPDF, yValue: number, driverNotes: IDriverNote[]): number {
      if (!driverNotes) return yValue;
      if (!driverNotes.length) return yValue;

      doc.setFontSize(16);
      doc.text(`Driver Notes`, 10, (yValue += 10));
      doc.setFontSize(12);

      driverNotes.forEach((driverNote: IDriverNote) => {
        doc.setFont("Helvetica", "italic");
        doc.text(`${driverNote.DriverName}`, 10, (yValue += 10));
        doc.setFontSize(10);
        doc.text(`${this.formatDate(driverNote.Timestamp!, true)}`, 10, (yValue += 10));
        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        doc.text(`${driverNote.Note}`, 10, (yValue += 10));
        yValue = this.insertNewLine(doc, yValue);
      });

      return yValue;
    },

    insertNewLine(doc: jsPDF, yValue: number): number {
      return yValue + 10;
    },

    insertLineSegment(doc: jsPDF, yValue: number): number {
      doc.line(10, yValue + 10, 195, yValue + 10);
      return yValue + 10;
    },

    insertNewPage(doc: jsPDF): number {
      doc.addPage();
      return 10;
    },

    /**
     * Return the DeliveryDateType (Not Confirmed, Will Call, Anytime) if it is specified on the Job. If
     * not, return the DeliveryDate time.
     * @author Nick Brahimir
     */
    getDeliveryDate(job: IJob): string {
      if (!job.DeliveryDetails.DeliveryDate) return "";

      const deliveryDateType = job.DeliveryDetails.DeliveryDateType;

      switch (DeliveryDateType[deliveryDateType]) {
        case DeliveryDateType.NotConfirmed:
          return DeliveryDateType.NotConfirmed;

        case DeliveryDateType.WillCall:
          return DeliveryDateType.WillCall;

        case DeliveryDateType.Anytime:
          return DeliveryDateType.Anytime;

        default:
          return this.formatDate(job.DeliveryDetails.DeliveryDate);
      }
    },

    /**
     * Converts an array of IApplications to a useable string to be printed to the PDF document.
     * @author Nick Brahimir
     */
    getStringApplications(data: IApplication[]): string {
      let result = "";
      data.forEach((application: IApplication) => {
        result += application.Name;

        const index = data.indexOf(application);
        if (index !== data.length - 1) result += ", ";
      });

      return result;
    },

    /**
     * Converts an array of IApplications to a useable string to be printed to the PDF document.
     * @author Nick Brahimir
     */
    getStringDrivers(data: IDriver[]): string {
      let result = "";
      data.forEach((driver: IDriver) => {
        result += `${driver.FirstName} ${driver.LastName}`;

        const index = data.indexOf(driver);
        if (index !== data.length - 1) result += ", ";
      });

      return result;
    },

    convertUnix(date: any): string | undefined {
      if (!date.seconds) {
        return;
      }

      return moment(date.seconds * 1000)
        .toDate()
        .toISOString();
    },

    formatDate: function (date: string | Date, dayOfWeek?: boolean) {
      const convertedDate = this.convertUnix(date);
      if (!dayOfWeek) return moment(convertedDate).format("MMMM Do YYYY, h:mm A");
      return moment(convertedDate).format("dddd MMMM Do YYYY, h:mm A");
    },

    formatDateShort: function (date: string | Date) {
      const convertedDate = this.convertUnix(date);
      return moment(convertedDate).format("MMM D YYYY");
    }
  }
});
