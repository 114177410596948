import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "..";
import { Quantity } from "@/core/_models/quantity.model";
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

class QuantitiesState {
  items: Quantity[] | undefined = undefined;
}

export const state: QuantitiesState = {
  items: undefined
};

const namespaced: boolean | undefined = true;

const getters = {
  items(state) {
    if (!state.items) return [];

    return state.items.sort((a, b) => (a.Index < b.Index ? -1 : 1));
  },
  item: (state) => (DatabaseId: string): Quantity | undefined => {
    if (!state.items) {
      return undefined;
    }

    const quantity = state.items.find((x) => x.DatabaseId == DatabaseId);

    return quantity;
  }
} as GetterTree<QuantitiesState, any>;

const mutations = {
  SET_QUANTITIES(state, quantities: Quantity[]) {
    state.items = quantities;
  },
  UPDATE_QUANTITY(state, quantity: Quantity) {
    if (!state.items) return;

    const existingItem = state.items.findIndex((x) => x.DatabaseId === quantity.DatabaseId);

    if (existingItem !== -1) {
      Vue.set(state.items, existingItem, quantity);
    } else {
      state.items.push(quantity);
    }
  },
  REMOVE_QUANTITY(state, DatabaseId: string) {
    if (state.items != undefined) {
      state.items = state.items?.filter((x) => x.DatabaseId != DatabaseId);
    }
  }
} as MutationTree<QuantitiesState>;

const actions = {
  async fetchQuantities({ commit, rootState }) {
    const data = Array<Quantity>();
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Quantities")
      .orderBy("Index")
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const quantity = doc.data() as Quantity;
          quantity.DatabaseId = doc.id;
          data.push(quantity);
        });
        commit("SET_QUANTITIES", data);
      });
  },
  pushQuantities({ commit, rootState }): any {
    const quantities = rootState.quantities.items;

    quantities.forEach((quantity: Quantity) => {
      commit("UPDATE_QUANTITY", quantity);
    });
  },
  async setQuantity({ commit, rootState }, quantity: Quantity): Promise<any> {
    if (quantity.DatabaseId === undefined) {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Quantities")
        .add(quantity)
        .then(() => {
          commit("UPDATE_QUANTITY", quantity);
        });
    } else {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Quantities")
        .doc(quantity.DatabaseId)
        .set(quantity)
        .then(() => {
          commit("UPDATE_QUANTITY", quantity);
        });
    }
  },
  async removeQuantity({ commit, rootState }, quantity: Quantity): Promise<any> {
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Quantities")
      .doc(quantity.DatabaseId)
      .delete()
      .then(async () => {
        commit("REMOVE_QUANTITY", quantity.DatabaseId);
      });
  }
} as ActionTree<QuantitiesState, any>;

export const quantities: Module<QuantitiesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
