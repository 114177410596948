// Vuex store
import store from "@/core/services/store";
// moment.js
import moment from "moment";
// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
// Models
import { IJob } from "@/core/_models/job.model";
import { IUser } from "@/core/_models/user.model";
import { IDriver } from "@/core/_models/driver.model";
import { IQuarryAssignment } from "@/core/_models/quarryAssignment.model";
import { DeliveryDateType } from "@/core/_constants/deliveryDateTypes.constant";

const JobsService = {
  /**
   * Gets all Jobs.
   *
   * @param clientId Client's ID
   * @returns {Promise<IJob[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllJobs(clientId: string): Promise<IJob[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Jobs`)
      .get()
      .then((querySnapshot) => {
        const data: IJob[] = [];

        querySnapshot.forEach((doc) => {
          let element = doc.data() as IJob;
          element.DatabaseId = doc.id;

          // ! Fixes properties from pre-existing Jobs; band-aid fix for older Jobs in an effort to
          // ! normalize data properties in Firebase db.
          element = this.fixJobs(element);

          data.push(element);
        });

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Gets Jobs in a specified date range.
   *
   * @param clientId Client's ID
   * @param start Start date
   * @param end End date
   * @returns {Promise<IJob[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getJobsDateRange(
    clientId: string,
    start: Date,
    end: Date,
    ordered?: boolean,
    includeCompleted?: boolean,
    includeCanceled?: boolean
  ): Promise<IJob[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Jobs`)
      .where("DeliveryDetails.DeliveryDate", ">=", start)
      .where("DeliveryDetails.DeliveryDate", "<=", end)
      .get()
      .then((querySnapshot) => {
        let data: IJob[] = [];

        querySnapshot.forEach((doc) => {
          const element = doc.data() as IJob;
          element.DatabaseId = doc.id;
          data.push(element);
        });

        // * Apply additional filters
        if (!includeCompleted) data = data.filter((job) => !job.Completed);
        if (!includeCanceled) data = data.filter((job) => !job.Canceled);

        // * Sort by DeliveryDetails.DeliveryDate
        if (ordered) {
          data = data.sort((a, b) => a.DeliveryDetails?.DeliveryDate - b.DeliveryDetails?.DeliveryDate);
        }

        return data;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  /**
   * Gets all non-completed Jobs.
   *
   * @param clientId Client's ID
   * @returns {Promise<IJob[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllActiveJobs(clientId: string): Promise<IJob[] | any> {
    return firebase.default
      .firestore()
      .collection(`Clients/${clientId}/Jobs`)
      .where("Completed", "!=", true)
      .get()
      .then((querySnapshot) => {
        const activeJobs: IJob[] = [];

        querySnapshot.forEach((doc) => {
          let element = doc.data() as IJob;
          element.DatabaseId = doc.id;

          // ! Fixes properties from pre-existing Jobs; band-aid fix for older Jobs in an effort to
          // ! normalize data properties in Firebase db.
          element = this.fixJobs(element);

          if (element.Canceled) return;

          activeJobs.push(element);
        });

        // Generate dateMapper and resulting Dashboard Jobs.
        const dateMapper: any = this.generateDateMapper(activeJobs);
        const resultJobs: IJob[] = this.generateJobDashboard(dateMapper, activeJobs);

        // Update store and return sortedData
        store.dispatch("SET_ACTIVE_JOBS", resultJobs);
        return resultJobs;
      })

      .catch((error) => {
        console.error(error);
      });
  },

  generateDateMapper(activeJobs: IJob[]): any {
    const dates: Date[] = [];
    const dateMapper = {};

    // Check for unique dates
    activeJobs.forEach((activeJob) => {
      const jobDate = new Date(activeJob.DeliveryDetails.DeliveryDate.seconds * 1000);
      jobDate.setHours(0, 0, 0);
      dates.push(jobDate);
    });

    // Order the unique dates by sorting them
    dates.sort((a: Date, b: Date) => {
      return moment.utc(a).diff(moment.utc(b));
    });

    dates.forEach((uniqueDate) => {
      dateMapper[uniqueDate.toISOString()] = [];
    });

    return dateMapper;
  },

  generateJobDashboard(dateMapper: any, activeJobs: IJob[]): IJob[] {
    const resultDateMapper: any = dateMapper;
    let resultJobs: IJob[] = [];

    // Map each activeJob to it's appropriate dateKey in the resultDateMapper.
    activeJobs.forEach((job: IJob) => {
      for (const [dateKey, jobsArray] of Object.entries(resultDateMapper)) {
        const jobDate: Date = new Date(job.DeliveryDetails.DeliveryDate.seconds * 1000);
        const dateKeyDate: Date = new Date(dateKey);

        // If the Days match, add that Job to the corresponding array in the resultDateMapper.
        if (
          jobDate.getFullYear() === dateKeyDate.getFullYear() &&
          jobDate.getMonth() === dateKeyDate.getMonth() &&
          jobDate.getDate() === dateKeyDate.getDate()
        ) {
          resultDateMapper[dateKey].push(job);
        }
      }
    });

    // "Anytime" DeliveryDateType criteria
    const anytimeCriteria: DeliveryDateType[] = [
      DeliveryDateType.Anytime,
      DeliveryDateType.ASAP,
      DeliveryDateType.NotConfirmed,
      DeliveryDateType.WillCall
    ];

    // "Scheduled" DeliveryDateType criteria
    const scheduledCriteria: DeliveryDateType[] = [
      DeliveryDateType.At,
      DeliveryDateType.After,
      DeliveryDateType.Before
    ];

    // Sort all dates in each respective dateArray for each dateKey in the resultDateMapper.
    for (const [dateKey, jobsArray] of Object.entries(resultDateMapper)) {
      const anytimeJobs: IJob[] = this.filterAndSortJobs(resultDateMapper[dateKey], anytimeCriteria);
      const scheduledJobs: IJob[] = this.filterAndSortJobs(resultDateMapper[dateKey], scheduledCriteria);
      const combinedJobs: IJob[] = anytimeJobs.concat(scheduledJobs);

      // Concat the sorted Jobs to the resultJobs; building the resulting array to be returned in the
      // getAllActiveJobs.
      resultJobs = resultJobs.concat(combinedJobs);
    }

    return resultJobs;
  },

  /**
   * Returns Jobs based on parameters.
   * ! Need to optimize this in the future - we're searching on 12K Jobs as of Oct 22, 2021.
   *
   * @param {string} clientId
   * @param {boolean} isCompleted
   * @param {string} searchCustomerName
   * @param {string} searchJobSite
   * @return {Promise<IJob[] | any>)}
   */
  async searchJobs(
    clientId: string,
    isCompleted: boolean,
    searchCustomerName: string,
    searchJobSite: string
  ): Promise<IJob[] | any> {
    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Jobs`)
        .where("Completed", "==", isCompleted)
        // .limit(1000)
        .get()
        .then((querySnapshot) => {
          const data: IJob[] = [];

          querySnapshot.forEach((doc) => {
            const element = doc.data() as IJob;
            element.DatabaseId = doc.id;
            data.push(element);
          });

          const sortedData: IJob[] = [];

          // If a Customer search param is provided, filter on that Customer
          if (searchCustomerName) {
            data.forEach((job: IJob) => {
              if (job.Customer?.Name?.toLowerCase().includes(searchCustomerName)) {
                // If NO JobSite search params, push here
                if (!searchJobSite) {
                  sortedData.push(job);
                  return;
                }

                // If JobSite search params were provided, filter based on those
                if (
                  job.JobSite?.Name?.toLowerCase().includes(searchJobSite) ||
                  job.JobSite?.CityAddress?.toLowerCase().includes(searchJobSite)
                ) {
                  sortedData.push(job);
                }
              }
            });
          }

          // Filter Jobs on JobSite.Name or JobSite.CityAddress
          else {
            data.forEach((job: IJob) => {
              if (
                job.JobSite?.Name?.toLowerCase().includes(searchJobSite) ||
                job.JobSite?.CityAddress?.toLowerCase().includes(searchJobSite)
              ) {
                sortedData.push(job);
              }
            });
          }

          // Sort by DeliveryDate
          const orderedData = sortedData.sort(
            (a: IJob, b: IJob) => b.DeliveryDetails?.DeliveryDate - a.DeliveryDetails?.DeliveryDate
          );

          return orderedData;
        })

        .catch((error) => {
          console.error(error);
        })
    );
  },

  /**
   * Creates a Job
   *
   * @param {string} clientId Client's ID
   * @param {IJob} data The Job.
   * @return {Promise<IJob[] | any>}  Promise-wrapped response.
   */
  async createJob(clientId: string, data: IJob): Promise<IJob[] | any> {
    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Jobs`)
        .add(data)
        .then(() => {
          const success = {
            status: 200,
            message: "OK"
          };

          return success;
        })

        // TODO - handle error
        .catch((error: any) => {
          console.error(error);
        })
    );
  },

  /**
   * Updates a Job
   *
   * @param {string} clientId Client's ID
   * @param {IJob} data The Job.
   * @return {Promise<any>}  Promise-wrapped response.
   */
  async updateJob(clientId: string, data: IJob): Promise<any> {
    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Jobs`)
        .doc(data.DatabaseId)
        .update(data)
        .then(() => {
          const success = {
            status: 204,
            message: "OK"
          };

          return success;
        })

        // TODO - handle error
        .catch((error: any) => {
          console.error(error);
        })
    );
  },

  /**
   * Deletes a Job
   *
   * @param {string} clientId Client's ID
   * @param {IJob} id The Job ID.
   * @return {Promise<IJob[] | any>}  Promise-wrapped response.
   */
  async deleteJob(clientId: string, id: string): Promise<IJob[] | any> {
    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Jobs`)
        .doc(id)
        .delete()
        .then(() => {
          const success = {
            status: 200,
            message: "OK"
          };

          return success;
        })

        // TODO - handle error
        .catch((error: any) => {
          console.error(error);
        })
    );
  },

  /**
   * Cancels a Job
   *
   * @param {string} clientId Client's ID
   * @param {IJob} data The Job to cancel
   * @returns {Promise<any>} Promise-wrapped response.
   * @author Nick Brahmimir
   */
  async cancelJob(clientId: string, data: IJob): Promise<any> {
    const canceledJob: IJob = data;
    canceledJob.Canceled = true;

    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Jobs`)
        .doc(canceledJob.DatabaseId)
        .update(canceledJob)
        .then(() => {
          const success = {
            status: 204,
            message: "OK"
          };

          return success;
        })

        // TODO - handle error
        .catch((error: any) => {
          console.error(error);
        })
    );
  },

  /**
   * Reopens (un-cancels) a Job
   *
   * @param {string} clientId The Client's ID
   * @param {IJob} data The Job to reopen
   * @returns {Promise<any>} Promise-wrapped response
   * @author Nick Brahmimir
   */
  async reopenJob(clientId: string, data: IJob): Promise<any> {
    const reopenedJob: IJob = data;
    reopenedJob.Canceled = false;

    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Jobs`)
        .doc(reopenedJob.DatabaseId)
        .update(reopenedJob)
        .then(() => {
          const success = {
            status: 204,
            message: "OK"
          };

          return success;
        })

        // TODO - handle error
        .catch((error: any) => {
          console.error(error);
        })
    );
  },

  /**
   * Gets all Driver Users.
   *
   * @param clientId Client's ID
   * @returns {Promise<IUser[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getAllDrivers(clientId: string): Promise<IDriver[] | any> {
    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Users`)
        .where("UserType", "==", "Driver")
        .get()
        .then((querySnapshot) => {
          const users: IUser[] = [];

          querySnapshot.forEach((doc) => {
            const element = doc.data() as IUser;
            element.UserId = doc.id;
            users.push(element);
          });

          const drivers: IDriver[] = [];

          users.forEach((user) => {
            const driver: IDriver = {
              FirstName: user.FirstName,
              LastName: user.LastName,
              Status: "Sent",
              UserId: user.UserId!
            };

            drivers.push(driver);
          });

          // Sort Drivers by FirstName then by LastName
          drivers.sort((a, b) => {
            const aFirstChar = a.FirstName.charAt(0);
            const bFirstChar = b.FirstName.charAt(0);
            if (aFirstChar > bFirstChar) {
              return 1;
            } else if (aFirstChar < bFirstChar) {
              return -1;
            } else {
              const aLastChar = a.LastName.charAt(0);
              const bLastChar = b.LastName.charAt(0);
              if (aLastChar > bLastChar) {
                return 1;
              } else if (aLastChar < bLastChar) {
                return -1;
              } else {
                return 0;
              }
            }
          });

          // Update store and return data
          store.dispatch("SET_DRIVERS", drivers);
          return drivers;
        })

        // TODO - handle error
        .catch((error) => {
          console.error(error);
        })
    );
  },

  /**
   * Gets a Driver by ID.
   *
   * @param clientId Client's ID
   * @param clientId Driver's ID
   * @returns {Promise<IUser[] | any>} Promise-wrapped data.
   * @author Nick Brahimir
   */
  async getDriver(clientId: string, driverId: string): Promise<IUser[] | any> {
    return (
      firebase.default
        .firestore()
        .collection(`Clients/${clientId}/Users`)
        .where("UserType", "==", "Driver")
        .get()
        .then((querySnapshot) => {
          let data: IUser | undefined = undefined;

          querySnapshot.forEach((doc) => {
            const element = doc.data() as IUser;
            element.UserId = doc.id;

            if (element.UserId === driverId) {
              data = element;
            }
          });

          return data;
        })

        // TODO - handle error
        .catch((error) => {
          console.error(error);
        })
    );
  },

  /**
   * Maps QuarryAssignments to Drivers, should they be missing any.
   *
   * @param {(IDriver[] | undefined)} drivers The array of drivers.
   * @param {IQuarryAssignment[]} quarryAssignments The saved quarry assignments.
   * @return {{IQuarryAssignment[]}} Returns finalized quary assignments.
   * @author Nick Brahimir
   */
  mapQuarryAssignments(
    drivers: IDriver[] | undefined | null,
    quarryAssignments: IQuarryAssignment[]
  ): IQuarryAssignment[] {
    const finalizedAssignments = quarryAssignments;

    // If Job has no Drivers assigned to it; return
    if (!drivers || !drivers.length) return finalizedAssignments;

    // Add any missing QuarryAssignments
    if (drivers.length !== quarryAssignments.length) {
      drivers.forEach((driver) => {
        const driverAssignmentLookup = quarryAssignments.find((a) => a.Driver.UserId === driver.UserId);

        if (!driverAssignmentLookup) {
          const newAssignment: IQuarryAssignment = {
            Driver: driver,
            Quarry: null
          };

          finalizedAssignments.push(newAssignment);
        }
      });
    }

    return finalizedAssignments;
  },

  /**
   * Filters Jobs based on an array of DeliveryDateType criteria, then sorts the Jobs by their
   * DeliveryDate.
   *
   * @param {IJob[]} data The Job data to process.
   * @param {DeliveryDateType[]} criteria The DeliveryDateType criteria.
   * @return {{IJob[]}} The resulting array of filtered and sorted Jobs.
   * @author Nick Brahimir
   */
  filterAndSortJobs(data: IJob[], criteria: DeliveryDateType[]): IJob[] {
    const result = data
      .filter((job) => criteria.includes(DeliveryDateType[job.DeliveryDetails.DeliveryDateType]))
      .sort((a: IJob, b: IJob) => a.SortIndex - b.SortIndex)
      .sort((a, b) => a.DeliveryDetails?.DeliveryDate - b.DeliveryDetails?.DeliveryDate)
    return result;
  },

  /**
   * Fixes Jobs by adding/removing fields in an attempt to normalize Job data in Firebase database.
   *
   * @param {IJob} job The job to be fixed
   * @return {*}  {IJob} The Job with fixed properties
   * @author Nick Brahimir
   */
  fixJobs(job: IJob): IJob {
    // * Fix:: job.Jobsite.Name
    // Fix missing "Name" property on job.Jobsite
    if (job.JobSite) {
      if (!job.JobSite.Name) job.JobSite.Name = job.JobSite.StreetAddress;
    }

    // * Fix:: job.AssignedQuarries
    // Initialize new property: AssignedQuarries for Jobs that don't have it in db.
    let assignedQuarries = job.AssignedQuarries;
    if (!assignedQuarries) assignedQuarries = [];
    assignedQuarries = this.mapQuarryAssignments(job.Drivers, assignedQuarries);
    job.AssignedQuarries = assignedQuarries;

    // * Fix:: job.DeliveryDetails.PriceType
    // Update pre-existing Jobs that have "Ton" to "Ton/Yard"
    if (job.DeliveryDetails.PriceType === "Ton") job.DeliveryDetails.PriceType = "Ton/Yard";

    // * Fix:: job.Canceled
    // Populates the job.Canceled property if it doesn't exist.
    if (!job.Canceled) job.Canceled = false;

    return job;
  }
};

export default JobsService;
