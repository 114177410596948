



























































// Vue
import Vue from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { IJobSite } from "@/core/_models/jobsite.model";

export default Vue.extend({
  name: "CreateJobsiteDialog",
  mixins: [validationMixin],

  data() {
    return {
      dialog: false,

      name: "",
      streetAddress: "",
      city: "",
      notes: ""
    };
  },

  methods: {
    submit(): void {
      if (!this.checkValid()) return;

      // Default the Jobsite Name to it's StreetAddress if no Name is provided
      if (!this.name) this.name = this.streetAddress;

      const newJobSite: IJobSite = {
        Name: this.name,
        StreetAddress: this.streetAddress,
        CityAddress: this.city,
        LocationType: "Address",
        Notes: this.notes,
        LastUsedTimestamp: new Date()
      };

      this.$emit("created-jobsite", newJobSite);
      this.close();
    },

    checkValid(): boolean {
      if (!this.name && !this.streetAddress) return false;
      return true;
    },

    close(): void {
      this.name = "";
      this.streetAddress = "";
      this.city = "";
      this.notes = "";

      this.dialog = false;
    }
  }
});
