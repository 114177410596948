import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "..";
import { User } from "@/core/_models/user.model";
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

class UsersState {
  items: User[] | undefined = undefined;
}

export const state: UsersState = {
  items: undefined
};

const namespaced: boolean | undefined = true;

const getters = {
  items(state) {
    if (!state.items) return [];

    return state.items.sort((a, b) => (a.UserType < b.UserType ? -1 : 1));
  },
  item: (state) => (UserId: string): User | undefined => {
    if (!state.items) {
      return undefined;
    }

    const user = state.items.find((x) => x.UserId == UserId);

    return user;
  }
} as GetterTree<UsersState, any>;

const mutations = {
  SET_USERS(state, users: User[]) {
    state.items = users;
  },
  UPDATE_USER(state, user: User) {
    if (!state.items) return;

    const existingItem = state.items.findIndex((x) => x.UserId === user.UserId);

    if (existingItem !== -1) {
      Vue.set(state.items, existingItem, user);
    } else {
      state.items.push(user);
    }
  },
  REMOVE_USER(state, UserId: string) {
    if (state.items != undefined) {
      state.items = state.items?.filter((x) => x.UserId != UserId);
    }
  }
} as MutationTree<UsersState>;

const actions = {
  async fetchUsers({ commit, rootState }) {
    const data = Array<User>();
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Users")
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const user = doc.data() as User;
          if (user.UserType !== "SystemUser") data.push(user);
        });
        commit("SET_USERS", data);
      });
  },
  pushUsers({ commit, rootState }): any {
    const users = rootState.users.items;
    users.forEach((user: User) => {
      commit("UPDATE_USER", user);
    });
  },
  async setUser({ commit, rootState }, user: User): Promise<any> {
    if (user.UserId === undefined) {
      await firebase.default
        .auth()
        .createUserWithEmailAndPassword(user.Email!, user.Password!)
        .then(async (userCredential) => {
          const createdUser = userCredential.user;
          user.UserId = createdUser?.uid;
          delete user.Password;
          await firebase.default
            .firestore()
            // .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Users")
            .collection("Clients/" + user.ClientId + "/Users")
            .doc(user.UserId)
            .set(user)
            .then(() => {
              commit("UPDATE_USER", user);
            });
          await firebase.default.firestore().collection("UserClients").doc(user.UserId).set({
            ClientId: user.ClientId
          });
        });
    } else {
      await firebase.default
        .firestore()
        .collection("Clients/" + user.ClientId + "/Users")
        .doc(user.UserId)
        .set(user)
        .then(() => {
          commit("UPDATE_USER", user);
        });
    }
  },
  async removeUser({ commit, rootState }, user: User): Promise<any> {
    await firebase.default
      .firestore()
      .collection("Clients/" + user.ClientId + "/Users")
      .doc(user.UserId)
      .delete()
      .then(async () => {
        commit("REMOVE_USER", user.UserId);
        await firebase.default.firestore().collection("UserClients").doc(user.UserId).delete();
      });
  }
} as ActionTree<UsersState, any>;

export const users: Module<UsersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
