/**
 * Changes a string to title case with extra spaces removed.
 */
export function toTitleCase(str: string) {
  const words = str.toLowerCase().split(/\s+/);

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
  }

  return words.join(" ");
}

/**
 * Changes a string to sentence case.
 */
export function toSentenceCase(str: string) {
  let result = str.replace(/(?<=\s\s)\s+/g, "");

  result = result.replace(/(?:^|\.\s+|\n)\w/g, function (match) {
    return match.toUpperCase();
  });

  return result;
}
