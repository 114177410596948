import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "..";
import { User } from "@/core/_models/user.model";
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { Client } from "@/core/_models/client.model";

class SystemUsersState {
  items: User[] | undefined = undefined;
}

export const state: SystemUsersState = {
  items: undefined
};

const namespaced: boolean | undefined = true;

const getters = {
  items(state) {
    if (!state.items) return [];

    return state.items.sort((a, b) => (a.UserType < b.UserType ? -1 : 1));
  },
  item: (state) => (UserId: string): User | undefined => {
    if (!state.items) {
      return undefined;
    }

    const user = state.items.find((x) => x.UserId == UserId);

    return user;
  }
} as GetterTree<SystemUsersState, any>;

const mutations = {
  SET_SYSTEM_USERS(state, users: User[]) {
    state.items = users;
  },
  UPDATE_SYSTEM_USER(state, user: User) {
    if (!state.items) return;

    const existingItem = state.items.findIndex((x) => x.UserId === user.UserId);

    if (existingItem !== -1) {
      Vue.set(state.items, existingItem, user);
    } else {
      state.items.push(user);
    }
  },
  REMOVE_SYSTEM_USER(state, UserId: string) {
    if (state.items != undefined) {
      state.items = state.items?.filter((x) => x.UserId != UserId);
    }
  }
} as MutationTree<SystemUsersState>;

const actions = {
  async fetchSystemUsers({ commit, rootState }) {
    const data = Array<User>();
    await firebase.default
      .firestore()
      .collection("Clients")
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach(async (clientDoc) => {
          const client = clientDoc.data() as Client;
          client.DocumentName = clientDoc.id;
          await firebase.default
            .firestore()
            .collection("Clients/" + client.DocumentName + "/Users")
            .get()
            .then(async (querySnapshot) => {
              querySnapshot.forEach((userDoc) => {
                const user = userDoc.data() as User;
                user.ClientId = client.DocumentName;
                data.push(user);
              });
              commit("SET_SYSTEM_USERS", data);
            });
        });
      });
  },
  pushSystemUsers({ commit, rootState }): any {
    const users = rootState.users.items;
    users.forEach((user: User) => {
      commit("UPDATE_SYSTEM_USER", user);
    });
  },
  async setSystemUser({ commit, rootState }, user: User): Promise<any> {
    if (user.UserId === undefined) {
      await firebase.default
        .auth()
        .createUserWithEmailAndPassword(user.Email!, user.Password!)
        .then(async (userCredential) => {
          const createdUser = userCredential.user;
          user.UserId = createdUser?.uid;
          delete user.Password;
          await firebase.default
            .firestore()
            .collection("Clients/" + user.ClientId + "/Users")
            .doc(user.UserId)
            .set(user)
            .then(() => {
              commit("UPDATE_SYSTEM_USER", user);
            });
          await firebase.default.firestore().collection("UserClients").doc(user.UserId).set({
            ClientId: user.ClientId
          });
        });
    } else {
      // TODO GOTTA DO MORE HERE IF user.clientID does not match original we have to move files around.
    }
  },
  async removeSystemUser({ commit, rootState }, user: User): Promise<any> {
    await firebase.default
      .firestore()
      .collection("Clients/" + user.ClientId + "/Users")
      .doc(user.UserId)
      .delete()
      .then(async () => {
        commit("REMOVE_SYSTEM_USER", user.UserId);
        await firebase.default.firestore().collection("UserClients").doc(user.UserId).delete();
      });
  }
} as ActionTree<SystemUsersState, any>;

export const systemUsers: Module<SystemUsersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
