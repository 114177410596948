import { GetterTree, MutationTree, ActionTree, Module } from "vuex";
import { RootState } from "..";
import { Application } from "@/core/_models/application.model";
import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

class ApplicationsState {
  items: Application[] | undefined = undefined;
}

export const state: ApplicationsState = {
  items: undefined
};

const namespaced: boolean | undefined = true;

const getters = {
  items(state) {
    if (!state.items) return [];

    return state.items.sort((a, b) => (a.Index < b.Index ? -1 : 1));
  },
  item: (state) => (DatabaseId: string): Application | undefined => {
    if (!state.items) {
      return undefined;
    }

    const application = state.items.find((x) => x.DatabaseId == DatabaseId);

    return application;
  }
} as GetterTree<ApplicationsState, any>;

const mutations = {
  SET_APPLICATIONS(state, applications: Application[]) {
    state.items = applications;
  },
  UPDATE_APPLICATION(state, application: Application) {
    if (!state.items) return;

    const existingItem = state.items.findIndex((x) => x.DatabaseId === application.DatabaseId);

    if (existingItem !== -1) {
      Vue.set(state.items, existingItem, application);
    } else {
      state.items.push(application);
    }
  },
  REMOVE_APPLICATION(state, DatabaseId: string) {
    if (state.items != undefined) {
      state.items = state.items?.filter((x) => x.DatabaseId != DatabaseId);
    }
  }
} as MutationTree<ApplicationsState>;

const actions = {
  async fetchApplications({ commit, rootState }) {
    const data = Array<Application>();
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Applications")
      .orderBy("Index")
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const application = doc.data() as Application;
          application.DatabaseId = doc.id;
          data.push(application);
        });
        commit("SET_APPLICATIONS", data);
      });
  },
  pushApplications({ commit, rootState }): any {
    const applications = rootState.applications.items;

    applications.forEach((application: Application) => {
      commit("UPDATE_APPLICATION", application);
    });
  },
  async setApplication({ commit, rootState }, application: Application): Promise<any> {
    if (application.DatabaseId === undefined) {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Applications")
        .add(application)
        .then(() => {
          commit("UPDATE_APPLICATION", application);
        });
    } else {
      await firebase.default
        .firestore()
        .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Applications")
        .doc(application.DatabaseId)
        .set(application)
        .then(() => {
          commit("UPDATE_APPLICATION", application);
        });
    }
  },
  async removeApplication({ commit, rootState }, application: Application): Promise<any> {
    await firebase.default
      .firestore()
      .collection("Clients/" + rootState.currentUser.client.DocumentName + "/Applications")
      .doc(application.DatabaseId)
      .delete()
      .then(async () => {
        commit("REMOVE_APPLICATION", application.DatabaseId);
      });
  }
} as ActionTree<ApplicationsState, any>;

export const applications: Module<ApplicationsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
