

















































































// Vue
import Vue from "vue";
import { mapGetters } from "vuex";
// Services
import AuthService from "@/core/services/auth.service";
// Models
import { _Error } from "@/core/_models/_error.model";
// Components
import JobDashboard from "@/views/dispatch/jobs/job-dashboard/JobDashboard.vue";

export default Vue.extend({
  name: "HeaderBar",
  components: { JobDashboard },

  data() {
    return {
      // Job Dashboard toggle
      toggleAside: false
    };
  },

  methods: {
    logout(): void {
      AuthService.logout().then((response: any | _Error) => {
        if (!response) return;

        this.$store.dispatch("LOGOUT");
        this.$vuetify.theme.themes.light.primary = "#f60039";
      });
    }
  },

  computed: {
    ...mapGetters({ currentUser: "currentUser" }),

    /**
     * Fetches the current Route name to display in HeaderBar title
     * @author  Nick Brahimir
     */
    currentRouteName: function (): string | null | undefined {
      return this.$route.name;
    }
  }
});
