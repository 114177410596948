import { IQuantity } from "@/core/_models/quantity.model";

export const DEFAULT_QUANTITIES: IQuantity[] = [
  {
    Index: 0,
    Name: "1"
  },
  {
    Index: 1,
    Name: "1-2"
  },
  {
    Index: 2,
    Name: "2"
  },
  {
    Index: 3,
    Name: "2-3"
  },
  {
    Index: 4,
    Name: "3"
  },
  {
    Index: 5,
    Name: "3-4"
  },
  {
    Index: 6,
    Name: "4"
  },
  {
    Index: 7,
    Name: "4-5"
  },
  {
    Index: 8,
    Name: "5"
  },
  {
    Index: 9,
    Name: "5-6"
  },
  {
    Index: 10,
    Name: "6"
  },
  {
    Index: 11,
    Name: "6-7"
  },
  {
    Index: 12,
    Name: "7"
  },
  {
    Index: 13,
    Name: "7-8"
  },
  {
    Index: 14,
    Name: "8"
  },
  {
    Index: 15,
    Name: "8-9"
  },
  {
    Index: 16,
    Name: "9"
  },
  {
    Index: 17,
    Name: "9-10"
  },
  {
    Index: 18,
    Name: "10"
  },
  {
    Index: 19,
    Name: "10-11"
  },
  {
    Index: 20,
    Name: "11"
  },
  {
    Index: 21,
    Name: "11-12"
  },
  {
    Index: 22,
    Name: "12"
  },
  {
    Index: 23,
    Name: "12-13"
  },
  {
    Index: 24,
    Name: "13"
  },
  {
    Index: 25,
    Name: "13-14"
  },
  {
    Index: 26,
    Name: "14"
  },
  {
    Index: 27,
    Name: "14-15"
  },
  {
    Index: 28,
    Name: "15"
  },
  {
    Index: 29,
    Name: "15-16"
  },
  {
    Index: 30,
    Name: "16"
  },
  {
    Index: 31,
    Name: "16-17"
  },
  {
    Index: 32,
    Name: "17"
  },
  {
    Index: 33,
    Name: "17-18"
  },
  {
    Index: 34,
    Name: "18"
  },
  {
    Index: 35,
    Name: "18-19"
  },
  {
    Index: 36,
    Name: "19"
  },
  {
    Index: 37,
    Name: "19-20"
  },
  {
    Index: 38,
    Name: "20"
  },
  {
    Index: 39,
    Name: "20-21"
  },
  {
    Index: 40,
    Name: "21"
  },
  {
    Index: 41,
    Name: "21-22"
  },
  {
    Index: 42,
    Name: "22"
  },
  {
    Index: 43,
    Name: "22-23"
  },
  {
    Index: 44,
    Name: "23"
  },
  {
    Index: 45,
    Name: "23-24"
  },
  {
    Index: 46,
    Name: "24"
  },
  {
    Index: 47,
    Name: "24-25"
  },
  {
    Index: 48,
    Name: "25"
  },
  {
    Index: 49,
    Name: "25-26"
  },
  {
    Index: 50,
    Name: "26"
  },
  {
    Index: 51,
    Name: "26-27"
  },
  {
    Index: 52,
    Name: "27"
  },
  {
    Index: 53,
    Name: "27-28"
  },
  {
    Index: 54,
    Name: "28"
  },
  {
    Index: 55,
    Name: "28-29"
  },
  {
    Index: 56,
    Name: "29"
  },
  {
    Index: 57,
    Name: "29-30"
  },
  {
    Index: 58,
    Name: "30"
  },
  {
    Index: 59,
    Name: "30-31"
  },
  {
    Index: 60,
    Name: "31"
  },
  {
    Index: 61,
    Name: "31-32"
  },
  {
    Index: 62,
    Name: "32"
  },
  {
    Index: 63,
    Name: "32-33"
  },
  {
    Index: 64,
    Name: "33"
  },
  {
    Index: 65,
    Name: "33-34"
  },
  {
    Index: 66,
    Name: "34"
  },
  {
    Index: 67,
    Name: "34-35"
  },
  {
    Index: 68,
    Name: "35"
  },
  {
    Index: 69,
    Name: "35-36"
  },
  {
    Index: 70,
    Name: "36"
  },
  {
    Index: 71,
    Name: "36-37"
  },
  {
    Index: 72,
    Name: "37"
  },
  {
    Index: 73,
    Name: "37-38"
  },
  {
    Index: 74,
    Name: "38"
  },
  {
    Index: 75,
    Name: "38-39"
  },
  {
    Index: 76,
    Name: "39"
  },
  {
    Index: 77,
    Name: "39-40"
  },
  {
    Index: 78,
    Name: "40"
  },
  {
    Index: 79,
    Name: "40-41"
  },
  {
    Index: 80,
    Name: "41"
  },
  {
    Index: 81,
    Name: "41-42"
  },
  {
    Index: 82,
    Name: "42"
  },
  {
    Index: 83,
    Name: "42-43"
  },
  {
    Index: 84,
    Name: "43"
  },
  {
    Index: 85,
    Name: "43-44"
  },
  {
    Index: 86,
    Name: "44"
  },
  {
    Index: 87,
    Name: "44-45"
  },
  {
    Index: 88,
    Name: "45"
  },
  {
    Index: 89,
    Name: "45-46"
  },
  {
    Index: 90,
    Name: "46"
  },
  {
    Index: 91,
    Name: "46-47"
  },
  {
    Index: 92,
    Name: "47"
  },
  {
    Index: 93,
    Name: "47-48"
  },
  {
    Index: 94,
    Name: "48"
  },
  {
    Index: 95,
    Name: "48-49"
  },
  {
    Index: 96,
    Name: "49"
  },
  {
    Index: 97,
    Name: "49-50"
  },
  {
    Index: 98,
    Name: "50"
  }
];
