





































































































// Vue
import Vue from "vue";
// Vuelidate
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// Models
import { ICustomer } from "@/core/_models/customer.model";
import { toTitleCase, toSentenceCase } from "@/core/utils/stringUtils";
import { debounce } from "@/core/utils/functionUtils";

const DEBOUNCE_RATE = 500;

export default Vue.extend({
  name: "CreateCustomerDialog",
  mixins: [validationMixin],

  data() {
    const self = this as any;
    return {
      dialog: false,

      name: "",
      email: "",
      phone: "",
      ext: null,
      businessAddress: "",
      notes: "",
      isCOD: false,

      // Debounced functions
      debounceCapitalizeName: debounce(self.capitalizeName, DEBOUNCE_RATE),
      debounceCapitalizeBusinessAddress: debounce(self.capitalizeBusinessAddress, DEBOUNCE_RATE),
      debounceCapitalizeNotes: debounce(self.capitalizeNotes, DEBOUNCE_RATE)
    };
  },

  validations: {
    name: { required }
  },

  methods: {
    /**
     * Submits the New Job to the server and updates the list.
     * @author Nick Brahimir
     */
    submit(): void {
      const newCustomer: ICustomer = {
        Name: this.name,
        Email: this.email,
        Phone: this.phone,
        Ext: this.ext,
        BusinessAddress: this.businessAddress,
        Notes: this.notes,
        IsCOD: this.isCOD,
        LastUsedTimestamp: new Date()
      };

      this.$emit("created-customer", newCustomer);
      this.close();
    },

    close(): void {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.ext = null;
      this.businessAddress = "";
      this.notes = "";
      this.isCOD = false;

      this.$v.$reset();
      this.dialog = false;
    },

    capitalizeName(): void {
      this.name = toTitleCase(this.name);
    },

    capitalizeBusinessAddress(): void {
      this.businessAddress = toTitleCase(this.businessAddress);
    },

    capitalizeNotes(): void {
      this.notes = toSentenceCase(this.notes);
    }
  },

  computed: {
    /**
     * Parses any Name errors.
     * @author Nick Brahimir
     */
    nameErrors(): string[] {
      const errors: string[] = [];
      if (!this.$v.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    }
  }
});
